[icono]:not([icono='']):before {
  font-family: 'Font Awesome 5 Free';
}

.sidenav .ti-home:before {
  content: '\f015' !important;
}

body {
  [icono='Personas']:before {
    content: '\f508' !important;
  }

  [icono='Dashboard']:before {
    content: '\f201' !important;
  }

  [icono='Mails']:before {
    content: '\f0e0' !important;
  }

  [icono='Facturación']:before {
    content: '\f571' !important;
  }

  [icono='Contactos']:before {
    content: '\f2b9' !important;
  }

  [icono='pagebuilder']:before {
    content: '\f15c' !important;
  }

  [icono='map']:before {
    content: '\f5a0' !important;
  }

  [icono='ABM']:before {
    content: '\f500' !important;
  }

  [icono='Mapa']:before {
    content: '\f279' !important;
  }

  [icono='Geolocalización']:before {
    content: '\f041' !important;
  }

  [icono='Gestoria']:before {
    content: '\f51c' !important;
  }

  [icono='Configuracion']:before {
    content: '\f013' !important;
  }

  [icono='Operaciones']:before {
    content: '\e67d' !important;
  }

  [icono='Mantenimiento Moviles']:before {
    content: '\f0ad' !important;
  }

  [icono='Contactos']:before {
    content: '\e719' !important;
  }

  [icono='Siniestros']:before {
    content: '\f5e1' !important;
  }

  [icono='Subastas']:before {
    content: '\f0e3' !important;
  }

  [icono='Dashboard Económico']:before {
    content: '\f1ec' !important;
  }

  [icono='Mentenimiento Moviles']:before {
    content: '\f10b' !important;
  }

  [icono='Multas']:before {
    content: '\f51e' !important;
  }

  [icono='Combustible']:before {
    content: '\f52f' !important;
  }

  [icono='Moviles']:before {
    content: '\f1b9' !important;
  }
}

@mixin fontAwesome {
  font-family: 'Font Awesome 5 Free';
  font-weight: 600;
  font-style: normal;
  line-height: 25px;
}

.dx-link {
  &.dx-icon-trash:before {
    content: '\f1f8';
    color: $secondary;
    padding: 0 3px;
    @include fontAwesome();
  }

  &.dx-icon-comment:before {
    content: '\f05a';
    padding: 1px 3px;
    color: $primary;
    @include fontAwesome();
  }

  &.dx-icon-edit:before {
    content: '\f14b';
    color: $terciary;
    padding: 0 3px;
    @include fontAwesome();
  }

  &.dx-icon-save:before {
    content: '\f0c7';
    color: $primary;
    padding: 0 3px;
    @include fontAwesome();
  }

  &.dx-icon-revert:before {
    content: '\f00d';
    color: $secondary;
    padding: 0 3px;
    @include fontAwesome();
  }
}
