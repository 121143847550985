/* VARIABLES */
$box-shadow: 0 0.46875rem 2.1875rem rgba(4, 9, 20, 0.03),
  0 0.9375rem 1.40625rem rgba(4, 9, 20, 0.03),
  0 0.25rem 0.53125rem rgba(4, 9, 20, 0.05),
  0 0.125rem 0.1875rem rgba(4, 9, 20, 0.03);

/*Default Colors*/
$primary: #54b9e7;
$background: #f5f6fa;
$secondary: #e35d6a;
$terciary: #333;
$quaternary: #222;
$quintuary: #c661b9;
$sextuary: #f6d55c;
$light: white;
$light2: #f5f6fa;

$gray-light: #d4d4d5;
