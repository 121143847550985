@import 'vars.scss';

.not-logged body {
  background-color: white !important;
}

.not-logged .cover-image {
    background: url("./img/bg-notlogged2.jpg") no-repeat center;
    background-size: cover !important;
    float: left;
    height: 100vh;
    padding-right: 0 !important;
    animation: zoom 45s infinite;
    width: 100%;

    @media all and (max-width: 768px) {
        display: none;
    }
}

.not-logged .cover-column {
  background-size: cover !important;
  float: left;
  height: 100vh;
  padding-right: 0 !important;
  -webkit-animation: zoom 45s infinite;
  animation: zoom 45s infinite;
  margin: 0px !important;
  padding: 0px !important;

  @media all and (max-width: 768px) {
    display: none;
  }
}

.elementor-background-overlay {
  background-color: transparent;
  background-image: linear-gradient(90deg, #e60d83 0%, #492db1 100%);
  opacity: 0.5;
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  position: absolute;

  @media all and (max-width: 768px) {
    display: none;
  }
}

.not-logged .login {
  float: left;
  padding: 0 40px;
  background-color: $background;
  height: 100vh;

  .login-btn {
    padding: 7px 10px !important;
    width: 100% !important;
    font-family: 'Poppins', sans-serif !important;
    font-weight: 500 !important;
    font-size: 20px;
    border-radius: 4px !important;
    color: #fff;
    background: -webkit-linear-gradient(#9c47fc, #356ad2) !important;
    margin: 15px 0px 0px !important;
    line-height: 23px !important;

    &:hover {
      background: -webkit-linear-gradient(#9c47fc, #356ad2) !important;
    }
  }

  .login-logo {
    margin-top: 50px;
    height: 70px;
    display: block;
    margin: 50px auto 0px;
  }

  h1 {
    font-size: 36px;
    margin-top: 30px;
    margin-bottom: 35px !important;
    line-height: 1.2 !important;
    text-align: center;
  }

  .form-login {
    max-width: 80%;
    margin: auto;

    button.btn.btn-primary:focus {
      box-shadow: none !important;
    }

    button.btn.btn-primary {
      box-shadow: none !important;
      background: #0069d9 !important;
    }

    button.btn.btn-primary:hover {
      background-color: #54b9e7 !important;
    }

    button.btn.btn-primary:disabled {
      background-color: #54b9e7 !important;
    }

    @media all and (max-width: 768px) {
      max-width: none;
    }
  }

  .login-loader {
    flex-direction: column;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px 0px;
  }

  .invalid-feedback {
    font-size: 14px;
    margin: 10px 0 20px !important;
  }

  .recover_pass {
    color: rgba(0, 0, 0, 0.85) !important;
    margin-top: 20px;

    &:hover {
      text-decoration: underline;
    }
  }

  .ui.warning.message {
    border: 0 !important;
    background-color: transparent !important;
    box-shadow: 0 0 0 !important;
    padding: 0 !important;
    margin: -25px 0 15px;

    .header {
      display: none !important;
    }

    p {
      color: $secondary;
      font-weight: 500;
      font-size: 16px;
    }
  }

  @media all and (max-width: 768px) {
    & {
      float: none;
      overflow: hidden;
    }
  }
}

.button-redirect {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px 20px;
  margin-top: 20px;
  margin: auto;
  font-size: 16px;
  font-weight: 500;
  color: white;
  background-color: #BB168F;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.button-redirect:hover {
  background-color: #512AAC;
}

@keyframes heartbeat {
  0%, 100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
}

.message-container {
  display: flex;
  align-items: flex-start;
  background-color: #f3f4f6;
  color: #1f2937;
  padding: 16px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  max-width: 600px;
  margin: 40px auto;
  font-family: Arial, sans-serif;
  font-size: medium;
  line-height: 1.5;
}

.message-container .icon {
  color: #ec5d50;
  margin-right: 12px;
  flex-shrink: 0;
  animation: heartbeat 1.5s infinite ease-in-out;
}

