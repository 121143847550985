$palette-color-key: 'base' !default;

$palette-blue: (
  'primary': (
    'base': #54b9e7,
    'light': #6ac7f1,
    'dark': #1696db,
  ),
  'secondary': (
    'base': #e35d6a,
    'light': #ec838e,
    'dark': #ef002c,
  ),
  'accent': (
    'base': #ffffff,
    'light': #ffffff,
    'dark': #dddddd,
  ),
  'foreground': (
    'base': #191919,
    'light': #333333,
    'dark': #111111,
    'darker': #000000,
  ),
  'background': (
    'base': #e9e9e9,
    'light': #ffffff,
    'dark': #dddddd,
  ),
);

$palette-blue-dark: (
  'primary': (
    'base': #1c617f,
    'light': #248bb8,
    'dark': #124f70,
  ),
  'secondary': (
    'base': #791a22,
    'light': #fff6e5,
    'dark': #ef002c,
  ),
  'accent': (
    'base': #1c617f,
    'light': #248bb8,
    'dark': #124f70,
  ),
  'foreground': (
    'base': #191919,
    'light': #333333,
    'dark': #111111,
    'darker': #000000,
  ),
  'background': (
    'base': #e9e9e9,
    'light': #ffffff,
    'dark': #dddddd,
  ),
);

$palette-red-colors: (
  'primary': (
    'base': #cc4e3f,
    'light': #d86c68,
    'dark': #9c382b,
  ),
  'secondary': (
    'base': #cc4e3f,
    'light': #d86c68,
    'dark': #9c382b,
  ),
  'accent': (
    'base': #ffffff,
    'light': #ffffff,
    'dark': #dddddd,
  ),
  'foreground': (
    'base': #191919,
    'light': #333333,
    'dark': #111111,
    'darker': #000000,
  ),
  'background': (
    'base': #e9e9e9,
    'light': #ffffff,
    'dark': #dddddd,
  ),
);

$palette-red-dark-colors: (
  'primary': (
    'base': #873428,
    'light': #d86c68,
    'dark': #9c382b,
  ),
  'secondary': (
    'base': #873428,
    'light': #d86c68,
    'dark': #9c382b,
  ),
  'accent': (
    'base': #873428,
    'light': #d86c68,
    'dark': #9c382b,
  ),
  'foreground': (
    'base': #191919,
    'light': #333333,
    'dark': #111111,
    'darker': #000000,
  ),
  'background': (
    'base': #e9e9e9,
    'light': #ffffff,
    'dark': #dddddd,
  ),
);

$palette-teal-colors: (
  'primary': (
    'base': #009687,
    'light': #52c7b7,
    'dark': #00675a,
  ),
  'secondary': (
    'base': #009687,
    'light': #52c7b7,
    'dark': #00675a,
  ),
  'accent': (
    'base': #212121,
    'light': #484848,
    'dark': #000000,
  ),
  'foreground': (
    'base': #191919,
    'light': #333333,
    'dark': #111111,
    'darker': #000000,
  ),
  'background': (
    'base': #e9e9e9,
    'light': #ffffff,
    'dark': #dddddd,
  ),
);

$palette-teal-dark-colors: (
  'primary': (
    'base': #007065,
    'light': #409c90,
    'dark': #004e44,
  ),
  'secondary': (
    'base': #009687,
    'light': #52c7b7,
    'dark': #00675a,
  ),
  'accent': (
    'base': #007065,
    'light': #409c90,
    'dark': #004e44,
  ),
  'foreground': (
    'base': #191919,
    'light': #333333,
    'dark': #111111,
    'darker': #000000,
  ),
  'background': (
    'base': #e9e9e9,
    'light': #ffffff,
    'dark': #dddddd,
  ),
);

$palette-blue-grey-colors: (
  'primary': (
    'base': #37474f,
    'light': #62727b,
    'dark': #102027,
  ),
  'secondary': (
    'base': #ffa726,
    'light': #ffd95b,
    'dark': #c77800,
  ),
  'accent': (
    'base': #4f3f37,
    'light': #7e6d64,
    'dark': #312622,
  ),
  'foreground': (
    'base': #191919,
    'light': #333333,
    'dark': #111111,
    'darker': #000000,
  ),
  'background': (
    'base': #e9e9e9,
    'light': #ffffff,
    'dark': #dddddd,
  ),
);

$palette-blue-grey-dark-colors: (
  'primary': (
    'base': #37474f,
    'light': #62727b,
    'dark': #102027,
  ),
  'secondary': (
    'base': #ffa726,
    'light': #ffd95b,
    'dark': #c77800,
  ),
  'accent': (
    'base': #ffa726,
    'light': #ffd95b,
    'dark': #c77800,
  ),
  'foreground': (
    'base': #191919,
    'light': #333333,
    'dark': #111111,
    'darker': #000000,
  ),
  'background': (
    'base': #e9e9e9,
    'light': #ffffff,
    'dark': #dddddd,
  ),
);

$evolutia: (
  'primary': (
    'base': #292b64,
    'light': #6878d6,
    'dark': #292b64,
  ),
  'secondary': (
    'base': #6878d6,
    'light': #d90479,
    'dark': #7224a6,
  ),
  'accent': (
    'base': #ffffff,
    'light': #ffffff,
    'dark': #dddddd,
  ),
  'foreground': (
    'base': #191919,
    'light': #333333,
    'dark': #111111,
    'darker': #000000,
  ),
  'background': (
    'base': #e9e9e9,
    'light': #ffffff,
    'dark': #dddddd,
  ),
);

$palette-evolutia-dark-colors: (
  'primary': (
    'base': #3c3e85,
    'light': #8d9df3,
    'dark': #6878d6,
  ),
  'secondary': (
    'base': #7224a6,
    'light': #a61b9c,
    'dark': #7224a6,
  ),
  'accent': (
    'base': #3c3e85,
    'light': #8d9df3,
    'dark': #6878d6,
  ),
  'foreground': (
    'base': #191919,
    'light': #333333,
    'dark': #111111,
    'darker': #000000,
  ),
  'background': (
    'base': #e9e9e9,
    'light': #ffffff,
    'dark': #dddddd,
  ),
);

$palettes: (
  'blue': $palette-blue,
  'evolutia': $evolutia,
  'red': $palette-red-colors,
  'teal': $palette-teal-colors,
  'yellowgrey': $palette-blue-grey-colors,
);

$dark-palettes: (
  'blue-dark': $palette-blue-dark,
  'evolutia-dark': $palette-evolutia-dark-colors,
  'red-dark': $palette-red-dark-colors,
  'teal-dark': $palette-teal-dark-colors,
  'yellowgrey-dark': $palette-blue-grey-dark-colors,
);

@function palette-color(
  $palette-colors,
  $name: 'primary',
  $variant: $palette-color-key
) {
  $color: null;
  $color-spectrum: map-get($palette-colors, $name);

  @if $color-spectrum {
    $color: map-get($color-spectrum, $variant);
  }

  @return $color;
}

/** Bootrap Breakpoins **/
$xs: 0px;
$sm: 576px;
$md: 768px;
$lg: 992px;
$xl: 1200px;
$xxl: 1800px;

@mixin respond-between($min, $max) {
  @media (min-width: $min) and (max-width: $max) {
    @content;
  }
}

@mixin respond-down($max) {
  @media (max-width: $max) {
    @content;
  }
}

@mixin phone-only {
  @media (max-width: $sm) {
    @content;
  }
}

@mixin tablet-portrait-up {
  @media (min-width: $sm) and (max-width: $md) {
    @content;
  }
}

@mixin tablet-landscape-up {
  @media (min-width: $md) and (max-width: $lg) {
    @content;
  }
}

@mixin desktop-up {
  @media (min-width: $xl) {
    @content;
  }
}

@mixin big-desktop-up {
  @media (min-width: $xxl) {
    @content;
  }
}

/* ANIMATIONS */

@-ms-keyframes spin {
  from {
    -ms-transform: rotate(0deg);
  }

  to {
    -ms-transform: rotate(360deg);
  }
}

@-moz-keyframes spin {
  from {
    -moz-transform: rotate(0deg);
  }

  to {
    -moz-transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes zoom {
  0% {
    background-size: 100% 100%;
  }

  50% {
    background-size: 106% 106%;
  }

  100% {
    background-size: 100% 100%;
  }
}

@mixin box-shadow {
  -webkit-box-shadow: 0px 14px 24px rgba(62, 57, 107, 0.2);
  box-shadow: 0px 14px 24px rgba(62, 57, 107, 0.2);
}

.hidden-transition {
  overflow: hidden;
  height: 0;
  opacity: 0;
  transition: height 0ms 400ms, opacity 400ms 0ms;
}

.show-transition {
  height: auto;
  overflow: visible;
  opacity: 1;
  transition: height 0ms 0ms, opacity 600ms 0ms;
}

@mixin scrollbar {
    &::-webkit-scrollbar {
        width: 5px;
    }

    &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px #0000004d;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #bbbbbb;
        outline: 0px;
    }
}

@mixin noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                    supported by Chrome, Edge, Opera and Firefox */
}
