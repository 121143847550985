@import '../../assets/vars.scss';
.mdal {
  position: fixed;
  z-index: 99999;
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.85);
  display: flex;
  flex-direction: column;

  &nav {
    width: 70%;
    display: flex;
    align-items: center;
    color: white;
    padding: 15px;
    min-height: 56px;
  }

  &back {
    color: white;
    font-size: 24px;
  }

  &title {
    margin: 0 0 0 10px;
    font-size: 16px;
  }

  &container {
    overflow: auto;
    min-height: 70%;
  }

  &__containerTittle {
    background-color: $primary;
    width: 100%;
    padding: 10px;
  }
  .mdalcontainer {
    background: #fff;
    position: relative;
    padding: 20px;
  }  
  .mdalcontainer h1 {
    padding: 0px 0px 20px;
  }
}
