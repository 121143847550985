.ui.form input:not([type]),
.ui.form input[type='date'],
.ui.form input[type='datetime-local'],
.ui.form input[type='email'],
.ui.form input[type='file'],
.ui.form input[type='number'],
.ui.form input[type='password'],
.ui.form input[type='search'],
.ui.form input[type='tel'],
.ui.form input[type='text'],
.ui.form input[type='time'],
.ui.form input[type='url'] {
  font-family: Lato, 'Helvetica Neue', Arial, Helvetica, sans-serif;
  margin: 0;
  outline: 0;
  -webkit-appearance: none;
  margin-left: 19px;
  margin-top: 9px;
  tap-highlight-color: rgba(255, 255, 255, 0);
  line-height: 1.21428571em;
  padding: 0.67857143em 1em;
  font-size: 1em;
  background: #fff;
  border: 1px solid rgba(34, 36, 38, 0.15);
  color: rgba(0, 0, 0, 0.87);
  border-radius: 0.28571429rem;
  -webkit-box-shadow: 0 0 0 0 transparent inset;
  box-shadow: 0 0 0 0 transparent inset;
  -webkit-transition: color 0.1s ease, border-color 0.1s ease;
  transition: color 0.1s ease, border-color 0.1s ease;
}

.ui.input {
  position: relative;
  font-weight: 400;
  font-style: normal;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  color: rgba(0, 0, 0, 0.87);
  width: 100%;
}

.ui.icon.input > i.icon:not(.link) {
  pointer-events: none;
  margin-left: -18px;
  margin-top: -3px;
}
