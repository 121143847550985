@import 'vars.scss';
@import 'mixins.scss';
@import 'fonts.scss';

/* GENERAL */

* {
  font-family: 'Open Sans', sans-serif;
}

*,
input,
select,
textarea,
option,
button {
  outline: none !important;
}

.p0 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.p0l {
  padding-left: 0 !important;
}

li {
  list-style: none;
}

b {
  font-weight: 500;
}

a {
  text-decoration: none;
  color: #000;

  &:hover,
  &:focus {
    text-decoration: none;
  }
}

.h-100 {
  height: 100vh !important;
}

footer {
  padding: 30px;
}

/* CONTAINER */

#container {
  padding-left: 240px !important;
  padding-right: 0 !important;
  height: 100%;
  overflow: hidden;
}

/* LOGIN */

@keyframes shrink {
  0% {
    background-size: 110% 110%;
  }

  100% {
    background-size: 100% 100%;
  }
}

div#root {
  background-color: #e9e9e9;
}

i.dsboard-chart__settings--button.ti-bar-chart {
  display: none;
}

.preloadData {
  text-align: center;
  display: block;
  padding-top: 26px;
  font-size: 16px;
}

.dsboard-chart {
  &.geo svg g path:hover {
    opacity: 0.6;
    transition: all 0.6s ease-in-out;
  }

  svg g g text {
    transition: all 0.6s ease-in-out;
  }
}

.dx-g-bs4-table-edit-cell:nth-child(2) input {
  visibility: hidden;
}

[id^='__lpform_'] {
  display: none;
}

@keyframes spinner1 {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

th [readonly] {
  display: none !important;
}

.table {

  th:first-of-type,
  td:first-of-type {
    width: 120px !important;
    display: block;
  }
}

@media (max-width: 767px) {
  html.not-logged {
    background: rgba(255, 255, 255, 0.9);

    &:hover {
      background: $light;
    }

    .row {
      align-items: stretch !important;
      padding-top: 50px;
    }
  }
}

#preloader {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 10001;
  width: 100%;
  height: 100%;
  overflow: visible;
  background: #232e3b url('http://files.mimoymima.com/images/loading.gif') no-repeat center center;
}

.dx-g-bs4-cursor-pointer {
  cursor: pointer;
}

.dx-g-bs4-user-select-none {
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
}

.dx-g-bs4-inactive:after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: $light;
  opacity: 0.7;
  pointer-events: none;
  z-index: 400;
}

.dx-g-bs4-overflow-hidden {
  overflow: hidden;
}

.dx-g-bs4-column-chooser-item {
  font-size: initial;
}

.dx-g-bs4-column-chooser-checkbox {
  margin-right: 10px;
}

.dx-g-bs4-sorting-indicator {
  top: 0;
  font-size: 11px;
}

span.dx-g-bs4-group-panel-item-icon {
  top: -2px;
  font-size: 11px;
  margin-left: -4px;
}

.tab-left .col-xl-2 {
  -webkit-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%;
}

.dx-g-bs4-group-panel-empty-message {
  padding: 11px 0;
}

.dx-g-bs4-table-detail-toggle-cell-icon {
  font-size: 9px;
  top: 0;
}

.dx-g-bs4-table-group-row-cell {
  font-size: 9px;
  top: 0;
  margin-right: 10px;
}

.dx-g-bs4-table-cell {
  overflow: hidden;
  text-overflow: ellipsis;
}

.dx-g-bs4-table {
  table-layout: fixed;
  border-collapse: separate;
  border-spacing: 0;
  margin: 0;
}

.dx-g-bs4-table-sticky {
  position: -webkit-sticky;
  position: sticky;
  z-index: 500;
}

.dx-g-bs4-table-head {
  top: 0;
}

.dx-g-bs4-table-foot {
  bottom: 0;
}

.dx-checkbox-checked .dx-checkbox-icon {
  color: #ef937e;
}

.dx-datagrid-column-chooser .dx-overlay-content {
  border: 0;
}

.contenedorPb .design_724 .dx-datagrid-headers .dx-datagrid-content .dx-datagrid-table .dx-dropdowneditor-input-wrapper {
  margin-top: 5px;
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.dx-selectbox-popup-wrapper .dx-overlay-content {
  border: 0 !important;
}

.contenedorPb .design_724 .dx-datagrid-headers .dx-datagrid-content .dx-datagrid-table .dx-row.dx-datagrid-filter-row .dx-dropdowneditor-input-wrapper.dx-selectbox-container .dx-texteditor-input-container input:not(.select2-offscreen):not(.select2-default):not(.select2-input):not([type='checkbox']):not([type='radio']):not(.select2-search__field) {
  border-bottom: 0 !important;
  margin-top: 0 !important;
  padding: 0 10px !important;
  font-size: 11px !important;
}

.dx-dropdownlist-popup-wrapper .dx-list:not(.dx-list-select-decorator-enabled) .dx-list-item-content {
  padding: 5px 4px !important;
  border-bottom: 1px solid rgba(33, 37, 41, 0.1) !important;
  padding: 8px !important;
}

.dx-g-bs4-table-container {
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}

.dx-g-bs4-grouping-control {
  width: 19px;
}

.dx-g-bs4-grouping-control-icon {
  top: 0;
  font-size: 12px;
  padding: 5px;
}

.dx-g-bs4-table-header-cell-wrapper {
  overflow: hidden;
  text-overflow: ellipsis;
  margin: -5px;
  padding: 5px;
  flex: 1;
}

.dx-g-bs4-table-header-cell-left {
  margin-right: 14px;
}

.dx-g-bs4-table-header-cell-right {
  margin-left: 14px;
}

.table .dx-g-bs4-table-edit-cell {
  padding: 5px;
}

button.dx-g-bs4-table-edit-command-cell {
  padding: 11px;
}

.dx-g-bs4-toolbar {
  align-items: center;
  min-height: 55px;
  flex: none;
}

.dx-g-bs4-paging-panel {
  flex: none;
}

.dx-g-bs4-toggle-button {
  cursor: pointer;
  display: inline-block;
  font-size: 9px;
  top: 0;
  margin-top: -0.5rem;
  margin-bottom: -0.5rem;
  box-sizing: content-box;
  min-width: 9px;
}

.dx-g-bs4-toggle-button-hidden {
  opacity: 0;
  cursor: default;
}

.dx-g-bs4-sorting-control-text,
.dx-g-bs4-table-tree-content {
  overflow: hidden;
  text-overflow: ellipsis;
}

.dx-g-bs4-resizing-control-wrapper {
  position: absolute;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  width: 16px;
  top: 0;
  right: -8px;
  height: 100%;
  cursor: col-resize;
  z-index: 100;
  opacity: 0;
}

.dx-g-bs4-header-cell:nth-last-child(2) .dx-g-bs4-resizing-control-wrapper {
  right: 0;
  width: 8px;
}

.dx-g-bs4-resizing-control-wrapper-active,
.dx-g-bs4-header-cell:hover .dx-g-bs4-resizing-control-wrapper {
  opacity: 1;
}

.dx-g-bs4-resize-control-line {
  opacity: 1;
  position: absolute;
  height: 50%;
  width: 1px;
  top: 25%;
  transition: all linear 100ms;
}

.dx-g-bs4-resize-control-line-first {
  left: 7px;
}

.dx-g-bs4-resize-control-line-second {
  left: 9px;
}

.dx-g-bs4-resize-control-line-active {
  left: 8px;
  height: calc(100% - 4px);
  top: 2px;
}

.dx-g-bs4-banded-header-cell:last-child {
  border-right: 0 !important;
}

.dx-g-bs4-banded-cell {
  border-bottom: none !important;

  &:last-child {
    border-right: none !important;
  }
}

.dx-g-bs4-filter-selector-item {
  font-size: initial;

  &:focus {
    outline: none;
  }
}

.dx-g-bs4-filter-selector-item-text {
  margin-left: 10px;
}

.dx-g-bs4-filter-selector-icon {
  width: 16px;
  height: 16px;
  fill: currentColor;
}

.dx-g-bs4-fixed-cell {
  z-index: 300;
}

.dx-g-bs4-table-summary-item {
  font-weight: bold;
}

.dx-g-bs4-table-invisible-row {
  visibility: hidden;
}

.dx-rg-bs4-table-header-title {
  /*overflow: hidden;*/
  text-overflow: ellipsis;
  text-transform: uppercase;
}

.dx-g-bs4-sort-indicator-invisible {
  opacity: 0;
}

.dx-g-bs4-fixed-block {
  position: absolute;
  width: 100%;
  text-align: center;
  margin-top: -15px;
}

.not-logged body form {
  .btn-group button#right {
    width: 33.33333333% !important;
    background-color: #dc3545 !important;
    border-color: #dc3545 !important;
    display: none;
  }

  #form-error {
    color: #dc3545;
    margin-bottom: 10px;
    font-size: 14px;
  }
}

@keyframes degree {
  0% {
    background: #1d5c87;
  }

  25% {
    background: #ff9f5d;
  }

  50% {
    background: #ffb887;
  }

  75% {
    background: #ff9f5d;
  }

  100% {
    background: #1d5c87;
  }
}

@keyframes degree2 {
  0% {
    color: #1d5c87;
  }

  25% {
    color: #ff9f5d;
  }

  50% {
    color: #ffb887;
  }

  75% {
    color: #ff9f5d;
  }

  100% {
    color: #1d5c87;
  }
}

/*.sweet-loading {
	display: block !important
  }*/

.jumbotron {
  h1 {
    font-family: 'Open Sans';
    margin-bottom: 12px;

    b {
      font-weight: 200;
    }
  }

  a {
    margin-bottom: 7px;
    color: #1d5c87;
    background-color: $light;
    padding: 10px 45px;
    font-size: 16px;
    font-weight: 500;
    border-radius: 4px;
    box-shadow: 0 1px 15px 1px rgba(62, 57, 107, 0.07);
    animation: degree2 15s ease infinite;
    display: inline-block;
  }

  i {
    font-size: 16px;
    font-weight: 600;
    padding-right: 6px;
    position: relative;
    top: 2px;
  }
}

#botonera {
  position: absolute;
  right: 46px;
  top: 137px;

  a {
    display: block;
    float: none;
  }
}

.sidenav .head-sidebar i {
  float: right;
  color: $secondary;
  font-size: 19px;
  cursor: pointer;
  padding-right: 15px;
  font-weight: 500;
  padding-top: 3px;
}

.true.ti-angle-down {
  display: none;
}

.sidenav {
  label {
    text-transform: uppercase;
    font-size: 11px;
    color: $light;
    letter-spacing: 2px;
    margin-bottom: 14px;
    padding-left: 58px;
    font-weight: 400;
    padding-top: 6px;
  }

  .liextended a {
    height: auto !important;

    li a {
      height: 30px !important;
    }
  }
}

.navbar .ti-back-right {
  color: $light;
  margin: 0 15px 0 10px;
  cursor: pointer;
}

.reductor.navbar {
  b {
    font-size: 13px;
    display: inline-block;
    margin: 0px 0px 0px 10px;
  }

  .nav-link {
    padding-top: 0;
    padding-bottom: 0;
  }
}

.navbar .ti-back-right,
.ti-settings {
  padding-left: 2px;
  font-size: 20px;
  margin-left: 5px;
  color: $primary;
  display: block;
  cursor: pointer;
}

.navbar .ti-back-right {
  margin-left: 0 !important;
}

.tooltip-inner {
  background-color: #3a4d5c;
  border-radius: 4px;
  color: #fff;
  max-width: 200px;
  padding: 5px 8px;
  text-align: center;
  border: 0;
}

.navbar {
  .dropdown a {
    padding-top: 0;
  }

  .dropdown-menu {
    border-top: 2px solid white !important;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    margin-top: 3px !important;
    background-color: rgba(46, 92, 136, 1) !important;
    border: 0;
    box-shadow: 0 0 0;
    position: absolute;
    float: none;
    z-index: 4543545;
    min-width: 152px;

    button {
      color: #232e3b !important;
      font-weight: 500 !important;
      cursor: pointer;
      background-color: transparent !important;
      padding: 5px 5px !important;
    }
  }

  .show .user-dropdown {
    display: block;
    position: absolute;
    top: 37px !important;
    width: 200px;

    @include respond-down($md) {
      top: 45px !important;
    }
  }

  .nav-themes {
    display: none;
    color: white;
    border: 0;
    margin: 0px;
    padding: 0px 0px 0px 20px;
    width: 100%;
  }

  .active .nav-themes {
    display: block;
  }
}

.dx-widget {

  input,
  textarea {
    font-family: 'Open Sans', sans-serif;
    font-weight: 400;
  }
}

.navbar {
  .dropdown-menu {
    a {
      padding-top: 0px !important;
      padding-bottom: 0px !important;
      font-size: 13px;
      color: white !important;
      font-weight: 300;
      background-color: transparent !important;
    }

    .dropdown-item:hover {
      background: transparent;
    }
  }

  #bell {
    position: relative;
  }

  .badge {
    background: red;
    position: absolute;
    right: -3px;
    top: -6px;
    font-size: 60%;
    color: $light;
    font-weight: 600;
    padding: 0.3em 0.4em;
    z-index: 100;
  }

  b {
    font-weight: 600;
  }
}

.sidenav::-webkit-scrollbar {
  -webkit-appearance: none !important;
  width: 3px !important;
  height: 7px !important;
}

body::-webkit-scrollbar {
  -webkit-appearance: none !important;
  width: 10px !important;
  height: 7px !important;
}

::-webkit-scrollbar-track {
  background: rgba(128, 135, 139, 0.1) !important;
  border-radius: 0 !important;
}

.sidenav::-webkit-scrollbar-thumb {
  cursor: pointer !important;
  border-radius: 6px !important;
  -webkit-transition: color 0.2s ease !important;
  transition: color 0.2s ease !important;
  background-color: #3a4d5c !important;
  border: 0;
}

body::-webkit-scrollbar-thumb {
  cursor: pointer !important;
  border-radius: 6px !important;
  -webkit-transition: color 0.2s ease !important;
  transition: color 0.2s ease !important;
}

.sidenav::-webkit-scrollbar-thumb {
  &:window-inactive {
    background-color: #3a4d5c !important;
  }

  &:hover {
    background: rgba(128, 135, 139, 0.8) !important;
    background-color: #3a4d5c !important;
    cursor: pointer !important;
  }
}

body::-webkit-scrollbar-thumb {
  &:hover {
    background: rgba(128, 135, 139, 0.8) !important;
    background-color: #3a4d5c !important;
    cursor: pointer !important;
  }

  background-color: rgb(164, 164, 164) !important;

  &:window-inactive,
  &:hover {
    background-color: rgb(123, 122, 122) !important;
  }
}

.nuevo #titulo-seccion {
  padding-top: 4px;
}

ul.ml-auto.navbar-nav li a {
  padding-left: 3px !important;
  padding-right: 0 !important;
}

body::-webkit-scrollbar-thumb {
 background: rgb(164, 164, 164) !important;
}

/* FORM */

.pantalla-form {
  border: 0;
  margin-top: 20px;
  -webkit-box-shadow: 0 2px 3px rgba(0, 0, 0, 0.03);
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.03);
  -webkit-transition: 0.5s;
  transition: 0.5s;
  float: left;
  background-color: $light;
  padding: 20px;
  width: 500px;
}

#container .nuevo {

  .ti-zoom-in,
  .ti-zoom-out {
    right: 67px;
  }
}

/* TABLE */

.dx-g-bs4-table-container {
  height: calc(100vh - 370px) !important;
}

.dx-g-bs4-table:nth-child(2) {
  height: auto;
  overflow-y: hidden;
}

#dashboardTemplate.contenedorPb {
  margin-top: 27px;
  padding: 35px 15px !important;
  background-color: #eee;
}

.contenedorPb {
  .ant-tabs .contenedorPb {
    height: inherit !important;
  }

  div.form-control {
    border: 0 !important;
    padding: 0;
  }

  >.templateTest.col-12.col-xl-12 .design_724>.templateTest.col-12.col-xl-12 button {
    margin-left: 15px;
  }
}

.dx-numberbox.dx-texteditor.dx-editor-outlined.dx-widget {
  //height: 40px;

  &.required.form-control {
    input {
      background-color: #f8d7da !important;
      border-color: #f5c6cb !important;
    }
  }
}

.reductor .ti-angle-up {
  display: none;
}

.nuevo .ti-angle-down,
.ti-zoom-out {
  display: none;
  color: #1d5c87 !important;
}

.reductor.nuevo .ti-angle-down {
  display: block;
}

table.table {
  margin-bottom: 0 !important;
}

.contenedorPb {

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0 0 15px 0;
  }
}

.dx-dropdowneditor-button,
.dx-dropdowneditor-icon {
  width: 40px !important;
  color: #3a4d5c;
  font-size: 15px;
}

::-webkit-input-placeholder {
  font-family: 'Open Sans', sans-serif !important;
}

.third_level_menu {
  &.false {
    display: none;
  }

  display: none;

  &.true {
    display: block;
  }
}

input {

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0;

    /* <-- Apparently some margin are still there even though it's hidden */
  }
}

.contenedorPb {
  .dd-menu .dd-menu-items ul {
    li {
      > {

        :focus,
        :hover {
          background-color: #d9e0e7 !important;
        }
      }

      button {
        padding: 6px 15px !important;
        font-size: 13px !important;
        font-family: 'Open Sans', sans-serif;
        font-weight: 400;
      }
    }

    padding: 0;
  }

  .popover-inner .py-2 {
    padding-top: 9px !important;
  }

  .py-2 {
    input[type='checkbox'] {
      height: auto;
      width: auto;
      margin-right: 8px;
    }

    .dropdown-item {
      padding: 2px 12px;
      font-size: 13px;
      color: #212529 !important;
      font-family: 'Open Sans', sans-serif;

      &:first-of-type {
        display: none !important;
      }
    }
  }
}

.table-dark {
  .table {
    background-color: #32383e !important;

    thead th,
    tr {
      vertical-align: bottom;
      border-bottom: 0px solid #4d555d !important;
    }
  }

  td,
  th,
  thead th {
    border-color: #32383e;
    border-width: 1px;
  }
}

.ti-email,
.ti-bell,
.navbar .badge {
  display: none !important;
}

.contenedorPb {
  .design_724 .table input:not(.select2-offscreen):not(.select2-default):not(.select2-input):not([type='checkbox']):not([type='radio']):not(.select2-search__field) {
    background-color: white !important;
  }

  .table .btn-outline-secondary {
    font-size: 20px;
    padding-bottom: 0;
    color: #1d5c87 !important;
    float: right;
    margin-bottom: 0;
    background-color: transparent;
    padding-right: 0;

    &:hover,
    &:active {
      font-size: 20px;
      padding-bottom: 0;
      color: #1d5c87 !important;
      float: right;
      margin-bottom: 0;
      background-color: transparent;
      padding-right: 0;
    }

    span:hover {
      background-color: transparent;
    }
  }

  .btn-outline-secondary:not(:disabled):not(.disabled).active {
    background-color: transparent;
    padding-right: 0;
  }
}

.crm-btn.crm-success {
  background-color: transparent;
  width: 100%;

  &:focus,
  &:hover {
    background-color: transparent;
    width: 100%;
  }

  .fa-plus {
    background-color: #1d5c87 !important;
    padding: 10px;
    margin-top: 10px;
    border-radius: 0.25rem;
  }
}

.btn.col-12 {
  padding: 10px;
  font-weight: 600;
}

.col-12 {
  float: left !important;
}

.third_level_menu {
  display: block;
}

.first_level_menu.true span {
  font-weight: 500 !important;

  span {
    font-weight: 300 !important;
  }
}

.third_level_menu span {
  font-weight: 400;
}

.ti-layout-media-center-alt {
  position: relative !important;
  top: 2px !important;
  padding-right: 7px;
  font-size: 16px !important;
  right: 0 !important;
}

.dx-rg-bs4-table-header-title {
  font-weight: 500;
}

/* th:nth-child(2) {
  visibility: hidden !important;
} */

.ant-table-thead>tr>th.ant-table-cell {
  color: #fff !important;
  background-color: #333 !important;
  padding: 5px 16px !important;
  line-height: 18px;
  font-size: 12px;
}

.aling-center {
  display: flex;
  width: 100%;
  align-content: center;
  align-items: center;
  justify-content: center;
}

.ant-table-content td.ant-table-cell {
  padding: 8px 16px !important;
  font-size: 12px;
}

.dx-g-bs4-toolbar button {
  padding: 0 7px 0 0;
  background-color: transparent;
  color: #1d5c87 !important;
  margin-bottom: 0;

  &:focus,
  &:hover,
  &.active {
    padding: 0 7px 0 0;
    background-color: transparent;
    color: #1d5c87 !important;
    margin-bottom: 0;
  }
}

.btn-outline-secondary:not(:disabled):not(.disabled) {

  &.active,
  &:active {
    background-color: transparent;
  }
}

.show>.btn-outline-secondary.dropdown-toggle {
  background-color: transparent;
}

.table th button {
  margin-left: 32px !important;
}

.dx-g-bs4-toolbar button {
  svg {
    position: relative;
    top: -2px;
  }

  .oi-eye {
    font-size: 18px;
  }
}

.table {

  .trash,
  .ti-plus {
    color: #f5001d;
  }

  .ti-eraser {
    font-size: 19px;
    position: relative;
    top: 3px;
    padding-right: 10px;
  }
}

table .ti-write {
  font-size: 19px;
  position: relative;
  top: 3px;
  padding-right: 10px;
}

.table {

  .ti-save,
  .ti-close {
    font-size: 19px;
    position: relative;
    top: 3px;
    padding-right: 10px;
  }

  .ti-eraser {
    font-size: 22px;
    top: 3px;
  }

  .ti-write {
    color: #1d5c87;
  }

  .ti-plus {
    padding-top: 6px;
    color: #1d5c87;
    font-size: 18px;
    position: absolute;
    top: 8px;
    font-weight: 900;
  }

  >thead>tr>th {
    text-transform: inherit;
    font-size: 13px;
    font-family: 'Open Sans', sans-serif;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  tr {
    border-top: 1px solid #e9ecef !important;

    td {
      border-top: 1px solid #e9ecef !important;
      margin-top: 0;
      font-family: 'Open Sans', sans-serif;
      text-rendering: optimizeLegibility;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      letter-spacing: 0.03em;
      vertical-align: baseline !important;

      button {
        padding: 8px 0 !important;
        margin: 0 !important;
      }
    }
  }
}

.reductor .table {
  tr td button {
    padding: 3px 0 !important;
  }

  td i {
    font-size: 19px !important;
  }
}

.ti-save {
  color: #53d730;
}

[role='tabpanel'] {
  float: left;
  width: 100%;

  .nuevo {
    display: none !important;
  }
}

table tr td .dd-menu-left button {
  background-color: #1d5c87;
  border: 0;
  color: $light;
  border-radius: 0.25rem !important;
  padding: 4px 7px !important;
  font-size: 13px;
  font-weight: 500;
  margin-top: 10px;
}

.card-header {
  background-color: transparent;
  position: absolute !important;
  left: 0;
  top: -3px;
  border-bottom: 0;
  z-index: 501;
  padding: 0 0 0 15px !important;

  .ml-auto {
    display: none !important;
  }
}

.Box.SummaryBox {
  color: $light;
  padding: 20px 20px 0;
  border-radius: 4px;

  &.Box--red {
    background-color: #f6653c !important;
  }

  h5 {
    font-family: 'Open Sans', sans-serif !important;
    margin: 0;
  }
}

.SummaryBox-value {
  font-size: 60px;
  position: relative;
  top: -10px;
  font-weight: 200;
}

.css-10nd86i {
  position: relative;
  margin-bottom: 15px;
  cursor: pointer;
}

.reductor .table .dx-g-bs4-table-edit-cell {
  padding: 0 !important;
}

.table .dx-g-bs4-table-edit-cell input {
  font-weight: 500;
  color: black;
  background-color: #fffcbb !important;
  margin-bottom: 0 !important;
  border: 0 !important;
  letter-spacing: 0.03em;
}

.reductor {
  .table {
    .dx-g-bs4-table-edit-cell input {
      padding-left: 0;
    }

    td {
      padding: 5px 0;
      letter-spacing: 0.5px;
    }
  }

  table tr td .dd-menu-left button {
    padding: 2px 7px !important;
    font-size: 12px;
    margin-top: 5px;
  }

  .kAMQHH {
    border-bottom: 5px solid #eee;
  }
}

.table {

  th,
  td {
    padding-left: 0 !important;
  }
}

.navbar .oi-menu,
.oi-x {
  display: none;
}

::placeholder,
.css-1492t68 {
  color: #5e5d5f !important;
  opacity: 1 !important;
  font-family: 'Open Sans', sans-serif !important;
  padding-left: 0px;
}

:-ms-input-placeholder,
::-ms-input-placeholder {
  color: #5e5d5f !important;
  font-family: 'Open Sans', sans-serif !important;
}

#dashboardTemplate {
  margin: 0 !important;
  width: 100%;
  height: calc(100vh - 56px);
}

.dx-datagrid-total-footer {
  border: 0 !important;
  background-color: #eee;

  >.dx-datagrid-content {
    padding: 0 !important;
  }
}

.gridContainer.complex {
  height: 575px !important;
}

#dashboardTemplate {
  .badge {
    color: $light;
    font-weight: 400;
    border-radius: 4px;
    padding: 10px 12px;
    font-size: 20px;
    display: block !important;
    font-family: 'Open Sans';
    white-space: normal !important;
    width: 270px;
    text-align: left;
  }

  .col-md-4 {
    margin-bottom: 25px;
  }
}

.p0 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.grafico {
  background-color: $light;
  border: 2px solid #eee;
  border-radius: 4px !important;
  padding: 20px;
  margin-bottom: 25px;
  width: 100%;
  text-align: center;
  height: 357px;
  overflow-y: hidden;
}

.dashboard-table {
  background-color: $light;
  border: 2px solid #eee;
  border-bottom: 0 !important;
  border-radius: 0 !important;
  border-top-left-radius: 4px !important;
  border-top-right-radius: 4px !important;
  padding: 20px !important;
  margin-bottom: 0px;
  width: 100%;
  text-align: center;
  height: 500px;
  overflow-y: auto;
}

.table-bottom {
  width: 100%;
  height: 25px;
  background: $light;
  margin-bottom: 25px;
  border: 2px solid #eee;
  border-top: 0 !important;
  border-radius: 0 !important;
  border-bottom-left-radius: 4px !important;
  border-bottom-right-radius: 4px !important;
}

.grafico {
  .ChartBox {
    border: 0 !important;
    box-shadow: 0 0 !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
    padding-bottom: 0 !important;

    h5 {
      display: none;
    }
  }

  h4 {
    margin-bottom: 14px;
    padding: 2px 0px;
    font-size: 14px;
    font-weight: 500;
    color: #212529;
    font-family: 'Open Sans';
    padding-bottom: 0;
    text-align: left;
  }
}

.dashboard-table h4 {
  margin-bottom: 14px;
  padding: 2px 0px;
  font-size: 14px;
  font-weight: 500;
  color: #212529;
  font-family: 'Open Sans';
  padding-bottom: 0;
  text-align: left;
}

.loading-chart {
  padding-top: 100px;
  font-size: 16px;
  font-weight: 600;
  color: #212529;
}

div.google-visualization-tooltip {
  transform: rotate(30deg);
}

#dashboardTemplate {
  h2 {
    font-size: 40px;
    margin: 20px auto 0;
    text-align: center;
    padding-bottom: 10px;
    border-bottom: 1px solid #212529;
    color: #212529;
    font-weight: 100;
    float: left;
    width: 100%;
  }

  .Box.SummaryBox {
    border-color: #d7dfe3;
    border-radius: 4px;
    -webkit-box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.05);
    box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.05);
    height: 109px;
    padding: 12px 10px;
    background: #f5374d;
    background: -moz-linear-gradient(-45deg, #f5374d 0%, #fa8593 100%);
    background: -webkit-linear-gradient(-45deg, #f5374d 0%, #fa8593 100%);
    background: linear-gradient(135deg, #f5374d 0%, #fa8593 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#F5374D", endColorstr="#FA8593", GradientType=1);

    &.Box--blue {
      background: #1d5c87;
      background: -moz-linear-gradient(-45deg, #1d5c87 0%, #ffb887 100%);
      background: -webkit-linear-gradient(-45deg, #1d5c87 0%, #ffb887 100%);
      background: linear-gradient(135deg, #1d5c87 0%, #ffb887 100%);
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#1d5c87", endColorstr="#FFB887", GradientType=1);
    }

    .SummaryBox-value {
      color: $light;
      font-size: 73px;
      margin-top: 1px;
      display: block;
      right: 20px;
      position: absolute;
      font-family: 'Open Sans', sans-serif;
      top: -3px;
      font-weight: 300;
    }
  }
}

.dx-g-bs4-user-select-none.text-primary {
  color: inherit !important;

  span {
    font-weight: 600 !important;
  }
}

@media (min-width: 1200px) and (max-width: 1599px) {

  .table .table,
  .dx-g-bs4-table,
  .table-responsive {
    min-width: auto !important;
    max-width: 1570px !important;
    width: 100% !important;
    table-layout: inherit !important;
  }

  #dashboardTemplate .badge {
    white-space: inherit !important;
    text-align: left;
    width: 220px;
    font-size: 20px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {

  .table .table,
  .dx-g-bs4-table {
    min-width: auto !important;
    max-width: 1170px !important;
    width: 100% !important;
    table-layout: inherit !important;
  }

  .ti-back-right {
    display: none !important;
  }

  .navbar .oi-menu {
    color: $primary;
    padding-right: 15px;
    padding-left: 10px;
    font-size: 16px;
    position: relative;
    top: -1px;
    cursor: pointer;
    display: block;
  }

  .oi-x {
    float: right;
    display: block;
    padding-right: 14px;
    padding-top: 1px;
    cursor: pointer;
  }

  #dashboardTemplate .badge {
    white-space: inherit !important;
    text-align: left;
    width: 220px;
    font-size: 20px;
  }
}

@media (max-width: 767px) {
  #hola {
    display: none !important;
  }

  .navbar {
    .ml-auto {
      flex-direction: row;
      margin-top: 0;
    }

    min-height: 50px;
    padding: 3px 14px !important;

    a {
      padding-bottom: 4px !important;
      padding-top: 4px !important;
    }
  }

  .avatar {
    display: none !important;
  }

  .contenedorPb {
    width: 100%;
    margin: 0;
    box-shadow: 0 0 0;
    padding: 20px 0px 20px 0px;
    height: auto !important;
    height: 100vh;
    padding-bottom: 60px;
  }

  .contenedorPb .divcontainer .templateTest.col-12.formelement {
    width: 96% !important;
    max-width: none !important;
  }

  .contenedorPb .templateTest.divcontainer {
    width: 100% !important;
    max-width: none !important;
  }

  .jumbotron h1 {
    font-size: 30px;
    margin-bottom: 19px;
  }

  #botonera {
    display: none;
  }

  .jumbotron {
    a {
      padding: 10px 15px;
      font-size: 13px;
    }

    border-radius: 0;
    padding: 30px 25px 40px;
  }

  #dashboardTemplate {
    &.contenedorPb {
      padding: 0 !important;
      overflow: auto !important;
    }

    margin-top: 15px !important;

    .badge {
      font-size: 17px;
    }

    .col-md-4 {
      margin-bottom: 15px;
    }

    .Box.SummaryBox {
      height: 65px;
      border-radius: 0;
    }

    .col-md-6 {
      margin-bottom: 10px;
    }

    .Box.SummaryBox .SummaryBox-value {
      font-size: 37px;
      font-weight: 100;
      top: 3px;
    }
  }

  .grafico {
    margin-bottom: 7px;
  }

  #dashboardTemplate {
    h5 {
      color: #455a64;
    }

    .badge {
      width: auto;
    }
  }

  .sc-iwsKbI {
    span {
      font-size: 13px;
    }

    padding: 3px 3px 3px 8px;

    &:focus,
    &:hover {
      padding: 3px 3px 3px 8px;
    }
  }

  .templateTest.col-12 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  ::-webkit-scrollbar {
    display: none;
  }

  .contenedorPb label {
    margin-bottom: 4px;
  }

  input.form-control,
  .css-10nd86i,
  .contenedorPb div.form-control {
    margin-bottom: 10px;
  }

  #submit-button {
    width: 100%;
  }
}

.dx-toolbar .dx-toolbar-items-container {
  height: 50px !important;
  overflow: visible;
}

.dx-toolbar-items-container {
  .dx-datagrid-group-panel .dx-group-panel-message {
    font-size: 15px;
    font-family: 'Open Sans', sans-serif !important;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    font-weight: 400 !important;
    color: #212529 !important;
    padding: 15px 0 5px;
  }

  .dx-toolbar-item.dx-toolbar-label {
    max-width: 400px !important;
  }
}

.dx-datagrid-headers {
  border: 0;
}

.dx-datagrid-borders .dx-datagrid-headers .dx-datagrid-table {
  border-bottom: 0;
}

.dx-datagrid-headers .dx-datagrid-table .dx-row>td {
  border-bottom: 0 !important;
  padding: 15px 8px !important;
  border-left: 1px solid #ddd !important;
}

.ant-tabs .ant-tabs {
  margin: 0 !important;
}

.dx-datagrid-headers {
  .dx-datagrid-table .dx-row>td {
    &:first-of-type {
      border-left: 0 !important;
    }

    &:last-of-type {
      border-right: 0 !important;
    }
  }

  border: 0 !important;
}

.dx-datagrid-borders>.dx-datagrid-rowsview {
  border: 0 !important;
}

.dx-toolbar-item .dx-toolbar-item-content .dx-datagrid-toolbar-button {
  background-color: transparent !important;
  border: 0;
  padding: 2px;
  border-radius: 4px;

  &:hover {
    background: #1d5c87 !important;
  }
}

.dx-toolbar-text-auto-hide .dx-button .dx-icon {
  color: white !important;
}

.ti-back-left {
  display: none;
  color: white !important;

  @media (max-width: $lg) {
    display: block;
    cursor: pointer;
  }
}

.sidenav-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  opacity: 0;
  height: 120vh;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 997;
  display: none;
}

.contenedorPb .design_724 .dx-highlight-outline .dx-texteditor input:not(.select2-offscreen):not(.select2-default):not(.select2-input):not([type='checkbox']):not([type='radio']):not(.select2-search__field).dx-texteditor-input {
  background-color: #fffdda !important;
  font-size: 12px !important;
  padding: 5px 10px !important;
  height: 30px;
}

.dx-datagrid-rowsview .dx-row-focused.dx-data-row:not(.dx-edit-row) {
  > {

    td:not(.dx-focused),
    tr>td:not(.dx-focused) {
      background-color: #c9c7c7 !important;
    }
  }

  .dx-command-edit .dx-link {
    background-color: #c9c7c7 !important;
  }
}

.dx-toolbar-text-auto-hide .dx-button .dx-button-content {
  padding: 4px 6px;
}

.dx-overlay-wrapper {
  z-index: 100000 !important;
}

.dx-popup-content.dx-dialog-content {
  min-width: 280px;
  padding: 20px 25px 5px;
}

.dx-dialog-message {
  font-size: 20px;
}

.dx-popup-bottom {
  .dx-button {
    padding: 5px 35px !important;
    font-size: 15px !important;
    text-decoration: none;
    border-radius: 4px !important;
    font-weight: 500;
    background-color: #1d5c87 !important;
    border: 0;

    span {
      color: white !important;
    }
  }

  &.dx-toolbar {
    float: left;
    height: 67px;
  }
}

.dx-datagrid-rowsview .dx-row-focused.dx-data-row:not(.dx-edit-row) .dx-command-edit .dx-link {
  color: white !important;
}

.dx-datagrid .dx-link {
  color: #1d5c87 !important;
  font-size: 16px !important;
  top: 0px !important;
  position: relative;
}

.dx-focused {
  outline: 0 !important;

  &:focus,
  &:hover,
  &:active {
    outline: 0 !important;
  }
}

.dx-datagrid-focus-overlay {
  border: 0 !important;
}

.dx-datagrid-content .dx-datagrid-table .dx-row>td.dx-editor-cell {
  padding-left: 2px !important;
  padding-right: 2px !important;

  &:first-child {
    padding-left: 4px !important;
  }
}

.contenedorPb>.templateTest.col-12.col-xl-12 .design_724>.templateTest.col-12.col-xl-12 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.dx-datagrid-content .dx-datagrid-table .dx-row .dx-editor-cell .dx-dropdowneditor {
  margin-left: 0px;
  padding-left: 0px;
}

table .dx-datebox-calendar {

  .dx-dropdowneditor-input-wrapper,
  input {
    height: 40px !important;
    margin-top: -2px;
  }
}

.lHLSz {
  background-color: #1d5c87;
  color: $light;
  border-color: #1d5c87;

  &:focus,
  &:hover {
    background-color: #1d5c87;
    color: $light;
    border-color: #1d5c87;
  }
}

.reductor {
  .lHLSz {
    padding: 5px 12px;
    font-size: 14px;

    &:focus,
    &:hover {
      padding: 5px 12px;
      font-size: 14px;
    }
  }

  .sc-iwsKbI {
    padding: 5px 12px;

    &:focus,
    &:hover {
      padding: 5px 12px;
    }
  }
}

.sc-iwsKbI span {
  font-weight: 500;
}

.reductor .sc-iwsKbI span {
  font-weight: 500;
}

.sc-gzVnrw .nuevo .oi {
  display: none;
}

.sc-iwsKbI .iWsTsl {
  color: #1d5c87;
  background-color: transparent;
}

.lHLSz .iWsTsl {
  color: $light;
}

.iWsTsl svg {
  font-weight: 600;
}

.kAMQHH {
  border-bottom: 6px solid #eee;
}

.jnZyxb {
  padding: 0;
}

.ant-tabs-nav {
  .ant-tabs-tab {
    background-color: $light;
    color: #333;
    border: 1px solid $gray-light;
    line-height: normal;
    margin-right: 0;
    font-size: 14px;
    padding: 10px 18px 7px;
    font-weight: 400px !important;
    border-top-left-radius: 0px !important;
    border-top-right-radius: 0px !important;
    font-size: 15px;
    height: 40px;
    padding: 8px 15px 6px;
    line-height: 27px;
    border-bottom: 0;
  }

  .ant-tabs-tab-active {
    color: #f47560;

    &:hover {
      color: #f47560;
    }
  }
}

.ant-tabs-content>div>.templateTest.col-12.col-xl-12 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.ant-tabs-vertical {
  padding-right: 0px;
}

textarea.form-control {
  min-height: 40px !important;
}

.dashboard-table .violeta.fa {
  color: #50132b !important;
}

.ui.link.cards {
  padding: 10px 0 !important;
  flex: 0 0 100% !important;
  max-width: 100% !important;
  min-height: 250px;
  margin: 0 !important;
}

.float-right.col-12 {
  float: right !important;
}

.dashboard-table .amarillo.fa {
  color: #fce903 !important;
}

.dashboard-table .verde.fa {
  color: #3bc472 !important;
}

.dx-datagrid-content {
  i {
    font-size: 16px !important;
    padding-right: 6px !important;

    &.rojo {
      color: #c51d34 !important;
    }
  }

  .dx-datagrid-table .dx-row>td[bgColor='rojo'] {
    background-color: #f37183;
    color: white !important;
  }

  i.violaceo {
    color: #8f415e !important;
  }
}

[bgColor='violaceo'] {
  background-color: #ca95a9;
}

.dx-datagrid-content i.amarillo {
  color: #fce903 !important;
}

[bgColor='amarillo'] {
  background-color: #fff9b4;
}

.dx-datagrid-content i.salmon {
  color: #f2c19f !important;
}

[bgColor='salmon'] {
  background-color: #ffe0ca;
}

.dx-datagrid-content i.naranja {
  color: #fb4221 !important;
}

[bgColor='naranja'] {
  background-color: #ffaa9a;
}

.dx-datagrid-content i.lila {
  color: #e3b1d2 !important;
}

[bgColor='lila'] {
  background-color: #f3d9ea;
}

.dx-datagrid-content i.celeste {
  color: #add8e6 !important;
}

[bgColor='celeste'] {
  background-color: rgba(218, 240, 248, 0.7);
}

.dx-datagrid-content i.blanco {
  color: white !important;
}

[bgColor='blanco'] {
  background-color: $light;
}

.dx-datagrid-content i.beige {
  color: #f2e7bf !important;
}

[bgColor='beige'] {
  background-color: #fff9e4;
}

.dx-datagrid-content i.verde-musgo {
  color: #8ca395 !important;
}

[bgColor='verde-musgo'] {
  background-color: #8ca395;
}

.dx-datagrid-content i {
  &.verde-claro {
    color: #3bc472 !important;
  }

  &.azul {
    color: #063971 !important;
  }
}

[bgColor='verde-claro'] {
  background-color: #e3fbe3;
}

.dx-datagrid-content i.verde {
  color: #3bc472 !important;
}

[bgColor='verde'] {
  background-color: #e3fbe3;
}

.dx-datagrid-content i.undefined {
  color: black !important;
}

[bgColor='undefined'] {
  background-color: $light;
}

.dx-datagrid-content i.verde-azulado {
  color: #698285 !important;
}

[bgColor='verde-azulado'] {
  background-color: #698285;
}

.dx-datagrid-content i.gris-medio {
  color: #bbbbbb !important;
}

[bgColor='gris-medio'] {
  background-color: #bbbbbb;
}

.dx-datagrid-content i.gris-claro {
  color: #e9e9e9 !important;
}

[bgColor='gris-claro'] {
  background-color: #e9e9e9;
}

.dx-datagrid-content .dx-datagrid-table .dx-row {

  &[bgColor='rojo']>td,
  &[bgColor='verde-azulado']>td,
  &[bgColor='violaceo']>td,
  &[bgColor='verde-musgo']>td {
    color: white !important;
  }
}

.dashboard-table .fa {
  padding-right: 5px;
}

section .section-title {
  text-align: center;
  color: #007b5e;
  margin-bottom: 50px;
  text-transform: uppercase;
}

#tabs {
  padding: 0 15px;
  color: #212529;

  ul {
    border-left: 0;
    margin-bottom: 30px;
    background-color: $light;
    border-radius: 4px;
  }

  .nav-tabs {
    .nav-link {
      border: 1px solid transparent;
      border-top-left-radius: 0.25rem;
      border-top-right-radius: 0.25rem;
      color: #212529 !important;
      font-size: 15px;
      font-weight: 400 !important;
      background-color: transparent !important;
      padding: 13px 0 13px;
    }

    .nav-item.show .nav-link,
    .nav-link.active {
      color: white !important;
      background-color: #232e3b !important;
      font-weight: 700 !important;
      border: 0 !important;
      height: 47px;
    }

    .nav-item {
      -webkit-box-flex: 1;
      -ms-flex: 1 1 auto;
      flex: 1 1 auto;
      text-align: center;

      a {
        margin-right: 0;
      }
    }
  }
}

.dx-datebox.dx-textbox.dx-texteditor.dx-datebox-date.dx-datebox-calendar {
  width: 100% !important;
  height: 40px !important;
}

.fa-address-book-o:before,
.fa-area-chart:before {
  content: '\e61d';
  font-family: 'themify';
  content: '\E644';
  background: rgba(60, 72, 86, 0.5);
  padding: 7px;
  border-radius: 500px;
  font-size: 13px;
  color: $light;
  font-weight: 900;
}

.fa-address-book-o:before {
  content: '\e6c7';
  font-family: 'themify';
  background-color: transparent !important;
  color: #ef937e;
  font-size: 15px;
  position: relative;
  top: -2px;
  font-weight: 500;
}

.fa-area-chart:before {
  content: '\E62E';
  background-color: #ffc488 !important;
}

.dx-datagrid-headers {
  .dx-icon-filter-operation-default {
    display: none !important;
  }

  .dx-editor-container input,
  table .dx-datebox-calendar .dx-dropdowneditor-input-wrapper,
  .dx-datebox.dx-textbox.dx-texteditor.dx-datebox-date.dx-datebox-calendar {
    height: 35px !important;
    margin-top: 5px !important;
  }

  table .dx-datebox-calendar .dx-dropdowneditor-input-wrapper input {
    border: 0 !important;
  }

  .dx-datagrid-table .dx-row.dx-datagrid-filter-row>td {
    padding-top: 0px !important;
    padding-bottom: 8px !important;
  }
}

.ti-angle-down.true:before {
  content: '\e648' !important;
  color: $light;
}

.dx-datagrid-header-panel {
  height: 60px !important;
}

.dx-command-edit-with-icons .fa:hover {
  z-index: 999;
}

.dx-datagrid-content .dx-command-edit-with-icons .fa:hover {
  transform: translateY(0) scale(1);
  box-shadow: 0px 0 0;
}

.dx-toolbar-items-container .dx-datagrid-group-panel .dx-group-panel-message,
.dx-toolbar-after .dx-datagrid-drag-action {
  color: #212529 !important;
  background: #eee;
  padding: 7px 15px;
  border-radius: 0;
  font-size: 14px;
}

.dx-toolbar-items-container .dx-datagrid-group-panel .dx-group-panel-message:hover {
  z-index: 999;
}

.dx-datagrid-headers {
  color: white !important;
  border-top-right-radius: 8px !important;
  border-top-left-radius: 8px !important;
  margin-top: -10px;
}

.contenedorPb .design_724 .dx-datagrid-headers .dx-datagrid-content .dx-datagrid-table .dx-row.dx-datagrid-filter-row input:not(.select2-offscreen):not(.select2-default):not(.select2-input):not([type='checkbox']):not([type='radio']):not(.select2-search__field) {
  margin-top: 5px !important;
  border-bottom: 1px solid #ef937e !important;
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  padding-left: 23px !important;
  font-size: 14px !important;
}

.dx-calendar-navigator .dx-calendar-caption-button.dx-button .dx-button-content,
.dx-calendar-navigator-previous-view.dx-button .dx-icon,
.dx-calendar-navigator-next-view.dx-button .dx-icon {
  color: #ef937e !important;
}

.dx-datagrid-headers .dx-datagrid-table .dx-row>td {
  border-bottom: 0 !important;
  padding: 10px 8px !important;
  border-left: 1px solid rgba(255, 255, 255, 0.5) !important;
  border-right: 0px solid #ddd !important;
}

.dx-datagrid {
  .dx-header-filter {
    color: #ff5128 !important;
  }

  .dx-header-filter-empty {
    color: rgba(149, 149, 149, 0.5) !important;
  }
}

.dx-datagrid-rowsview .dx-row-focused.dx-data-row:not(.dx-edit-row)> {

  td,
  tr>td {
    border-top: 2px solid white !important;
    border-bottom: 2px solid white !important;
  }
}

.ti-back-left:before {
  content: '\e68e';
}

.dx-datagrid .dx-row-lines:hover {
  box-shadow: 0 2px 3px -2px rgba(62, 57, 107, 0.2);
}

.contenedorPb .duplicador.divcontainer .formelement {
  margin-bottom: 0 !important;
}

.dx-datebox-calendar {

  .dx-dropdowneditor-input-wrapper,
  input {
    height: 40px !important;
  }
}

.dx-scrollbar-hoverable.dx-scrollable-scrollbar-active .dx-scrollable-scroll-content,
.dx-scrollable-scroll-content {
  background-color: #c9c7c7;
}

.dx-texteditor-container input.dx-texteditor-input {
  margin-bottom: 0 !important;
}

.dx-list.dx-list-with-search .dx-scrollable-wrapper {
  margin: 10px 0 20px !important;
  float: left;
}

.dx-overlay-wrapper.dx-dialog.dx-popup-wrapper.dx-dialog-wrapper.dx-dialog-root.dx-overlay-modal.dx-overlay-shader .dx-overlay-content .dx-button-has-text .dx-button-content {
  padding: 4px 4px 4px;
  width: 94px;
}

.dx-calendar-cell span {
  font-family: 'Open Sans', sans-serif !important;
}

.dx-calendar-body thead tr th {
  font-family: 'Open Sans', sans-serif !important;
  color: #212529 !important;
}

.dx-calendar-navigator .dx-calendar-caption-button.dx-button .dx-button-content span {
  font-family: 'Open Sans', sans-serif !important;
}

.dx-calendar-body thead tr th:nth-child(2) {
  visibility: visible !important;
}

.dx-datebox-wrapper-date.dx-datebox-wrapper-calendar>div {
  border: 0;
}

.dx-calendar-cell.dx-calendar-selected-date {

  &.dx-calendar-contoured-date,
  &.dx-calendar-today.dx-calendar-contoured-date {
    -webkit-box-shadow: 0 0 0 !important;
    box-shadow: 0 0 0 !important;
    background: #ef937e !important;
  }
}

.dx-overlay-content {
  .dx-popup-bottom .dx-button {
    padding: 5px 9px !important;
  }

  .dx-toolbar-before {
    position: absolute;
    top: 0 !important;
    left: 0 !important;
  }
}

.dx-datagrid-headers {

  td.dx-command-edit.dx-command-edit-with-icons,
  .dx-command-expand {
    background: #3a4d5c;
    border-top-left-radius: 0px;
  }
}

.dx-datagrid .dx-datagrid-content .dx-datagrid-table .dx-row .dx-command-edit-with-icons .dx-link {
  width: 26px !important;
}

.form-check {
  padding-left: 0 !important;
  height: 63px !important;
}

select.form-control:not([size]):not([multiple]) {
  height: 40px;
}

.ant-btn {
  &:not(.ant-btn-circle):not(.ant-btn-circle-outline).ant-btn-icon-only {
    padding-right: 8px;
    padding-left: 8px;
    border: 0;
  }

  &.ant-btn-icon-only i {
    font-size: 20px;
    margin-left: -10px;
  }
}

.file-manager-nav__title {
  margin: 7px 15px 0 0;
}

.dx-datagrid-header-panel .dx-item.dx-toolbar-item.dx-toolbar-label {
  position: absolute;
  left: 323px;
  top: 3px;
}

.contenedorPb .design_724 .dx-datagrid-search-panel.dx-textbox.dx-texteditor input:not(.select2-offscreen):not(.select2-default):not(.select2-input):not([type='checkbox']):not([type='radio']):not(.select2-search__field) {
  background-color: #eee !important;
  border: 0 !important;
  color: #212529 !important;
  border-radius: 6px !important;
  outline: 0 !important;
  height: 33px !important;
  padding-left: 10px;
  margin-top: 1px !important;
}

.dx-toolbar-items-container .dx-datagrid-group-panel .dx-group-panel-message {
  font-size: 12px !important;
  padding-bottom: 8px !important;
}

.dx-searchbox .dx-icon-search:before {
  color: transparent;
  padding-left: 5px;
  position: relative;
  top: 1px;
}

.dx-texteditor-empty .dx-icon-search:before {
  color: #212529;
}

.dx-datagrid-filter-row .dx-menu .dx-menu-horizontal {

  .dx-menu-item-text,
  .dx-menu-item-popout {
    display: block !important;
  }
}

.dx-menu-base .dx-menu-item .dx-menu-item-content .dx-menu-item-popout-container {
  left: -2px;
  top: 5px;
}

.dx-overlay-content.dx-popup-normal.dx-popup-draggable .dx-toolbar-after {
  left: 192px !important;
  top: 0px !important;
  position: absolute;
}

.dashboard_block {
  width: 100%;
  height: 100vh;
  background-color: #a2b5e6;
  border: 1px dashed #ebebeb;
}

.dashboard-editor-container {
  display: flex;
  background-color: $light;
  position: relative;

  @include respond-down($md) {
    flex-direction: column;
  }

  .dashboard-editor-options {
    width: 40%;
    padding: 20px;
    border-right: solid 1px #f2f3f8;
    position: relative;

    @include respond-down($md) {
      width: 100%;
    }

    .streamline {
      height: 100vh;
      overflow-y: auto;
      background: linear-gradient(61deg,
          rgba(60, 72, 86, 1) 0%,
          rgba(35, 46, 59, 1) 25%);
      padding: 5px 30px 5px 30px;
      box-shadow: 10px 1px 15px 0 rgba(62, 57, 107, 0.07);

      &.edicionmenu {
        max-width: 40%;
      }

      .sl-item {
        padding-bottom: 9px !important;
      }

      h3 {
        color: $light;
        font-size: 15px;
        font-weight: 300;
        margin: 13px 0 14px;
      }
    }
  }

  .dashboard-editor-selected-options {
    width: 60%;
    padding: 20px;
    position: relative;

    @include respond-down($md) {
      width: 100%;
    }
  }

  .options {
    position: relative;
    display: flex;
    flex-direction: row;
    position: absolute;
    right: 0px;
    top: -30px;

    a {
      display: inline-block;
      padding: 0px 7px;
    }

    i {
      font-size: 16px;
      font-weight: 500 !important;
      color: $secondary;
      cursor: pointer;

      &.fa-undo:before {
        content: '\f0e2';
      }
    }
  }
}

.streamline .sl-item,
#dashboard_graph .sl-item.b- {
  border-color: rgba(120, 130, 140, 0.3);
  position: relative;
  padding-bottom: 7px;
}

.streamline .sl-item .sl-content,
#dashboard_graph .sl-item.b- .sl-content {
  font-size: 13px;
  cursor: move;
  color: $light;
  border: 1px solid rgba(255, 255, 255, 0.4);
  padding: 9px 7px;
  border-radius: 5px;
  background: #232e3b;
  transition: all 0.6s ease-in-out;
}

#dashboard_graph .sl-item.b- {
  .sl-content {
    color: black;
    height: 52px;
    font-size: 16px;
    font-weight: 300;
    padding-top: 11px;
    color: $light;
    background-color: #fff6e5;
    border: 2px solid $light;
  }

  position: relative;
}

.streamline .sl-item:focus {
  outline: 0;
}

#dashboard_graph .sl-item.b- .sl-content span {
  font-size: 14px;
  font-weight: 400;
  color: black;
}

.tipo-dato {
  background: #3a4d5c;
  padding: 2px 5px;
  border-radius: 5px;
  color: $light;
  position: relative;
  font-size: 11px;
  text-transform: lowercase;
  border: none;
  display: inline-block;
}

.sl-content.sl-content-flex-container {
  display: flex;
  justify-content: space-between;

  .sl-content-icons-group {
    width: 15%;
    color: $terciary;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  .sl-content-icons-group>* {
    margin: 0px 5px;
  }

  .sl-content-description {
    width: 75%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .sl-content-options-after {
    width: 10%;
    display: flex;
    justify-content: flex-end;
    align-content: center;
  }
}

.dashboard-editor-options-list {
  .sl-content.sl-content-flex-container .sl-content-icons-group>* {
    color: $light !important;
  }
}

#dashboard_graph {
  .prefix {
    background: #3a4d5c;
    padding: 2px 5px;
    border-radius: 5px;
    color: $light;
    float: left;
    position: relative;
    left: 58px;
    font-size: 11px !important;
    text-transform: lowercase;
    top: -1px;
    border: 3px solid $light;
  }

  .sl-item.b- .sl-content {
    .prefix {
      background-color: #ef937e !important;
      color: white !important;
      left: -33px !important;
      top: -3px !important;
    }

    .ti-id-badge,
    .ti-bar-chart-alt,
    .ti-layout-list-thumb {
      font-size: 21px;
      color: #ef937e;
    }
  }
}

#dashboard_graph .prefix {
  background: transparent;
  border-radius: 0px;
  left: 39px;
  border: 0;
  border-right: 1px solid rgba(255, 255, 255, 0.6);
  width: 55px;
}

#dashboard_graph .sl-item.b- {
  &:focus {
    outline: 0;
  }

  .sl-content .ti-bar-chart-alt {
    font-size: 19px;
  }
}

.streamline {
  .sl-item .sl-content:hover {
    background-color: #3a4d5c !important;
  }

  .ti-plus {
    padding-right: 8px;
    position: relative;
    top: 1px;
    font-size: 15px;
  }
}

#dashboard_graph .sl-item.b- .sl-content .ti-close {
  color: #333333;
  cursor: pointer;
}

.dsboard.col-xl-9 {
  float: left;
}

.dashboardNew {

  .ti-layout-sidebar-left,
  .ti-filter {
    position: absolute;
    right: 29px;
    top: 62px;
    z-index: 10002;
    font-size: 14px;
    cursor: pointer;
    font-weight: 100;
    color: #212529;
    border: 0;
  }
}

.columnOptions .ti-angle-right,
.graphOptions .ti-angle-right,
.columnOptions .ti-arrows-vertical {
  padding-right: 8px;
  color: #ef937e;
  font-weight: 600;
  position: relative;
  top: 1px;
}

.dashboardNew .ti-filter {
  right: 55px;
  font-size: 14px;
  color: #212529;
  top: 61px;
}

.dsboard.col-xl-12 {
  padding: 0px !important;
}

.prefix {
  background-color: #ef937e;
  border-radius: 4px;
  font-size: 10px;
  color: $light;
  padding: 2px 4px;
  position: relative;
  top: -1px;
  margin-right: 15px;
}

.list .ant-tabs {
  margin: -26px 0 0px !important;
  padding: 0 !important;
  width: 100% !important;
  max-width: 100% !important;
}

.desagregado b {
  background-color: #1d5c87 !important;
  color: $light;
  font-weight: 500;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  padding: 2px 7px 2px 8px;
  font-size: 12px;
  margin: 0 0 0 8px;
  position: relative;
  top: -1px;
}

.desagregadoInner {
  background-color: $light;
  color: black;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  font-weight: 500;
  font-size: 12px;
  padding: 2px 7px 2px 8px;
}

.dsboard-template {
  padding: 0;
}

#modovista {
  cursor: pointer;
}

.dsboard-chart {
  background-color: #ffffff !important;
  border: none;
  box-shadow: 0px 1px 15px 1px rgba(62, 57, 107, 0.03);
  padding: 60px 25px 15px;
  display: none;
  border-radius: 0px !important;

  &.dsboard-chart-list {
    overflow: hidden;

    &:hover {
      overflow-y: auto;
      transition: all 0.2s;
    }

    col.dx-col-fixed {
      display: none !important;
    }

    td.dx-command-edit.dx-command-edit-with-icons {
      display: none;
    }

    td.dx-command-edit.dx-command-edit-with-icons+td {
      display: none;
    }
  }

  &::-webkit-scrollbar {
    width: 5px !important;
    height: 7px !important;
    transition: all 0.2s;
  }

  &:hover {
    @include box-shadow();
  }
}

.dsboard-chart--height-2 {
  height: 340px;
}

.dsboard-chart .dsboard-chart__settings {
  top: 2px;
  display: block;
}

.graphOptions div,
.DcolumnOptions div {
  background-color: #fff6e5;
  width: 33.333333333%;
  float: left;
  padding: 14px 20px;
  border: 3px solid $light;
  color: #212529;
  border-radius: 6px;
  cursor: pointer;
  transition-timing-function: ease-in;
  transition: 0.02s;
}

.XcolumnOptions div span {
  float: right;
  border-radius: 6px;
  padding: 2px 6px;
  color: $light;
  background: #ef937e;
  font-size: 11px;
  display: none;
}

.YcolumnOptions div {
  user-select: none;
  padding: 16px;
  margin: 0px 0px 8px;
  background: rgb(255, 246, 229);
  border-radius: 6px;
}

.XcolumnOptions div span.si {
  display: block;
  cursor: pointer;
}

.graphOptions div:hover,
.YcolumnOptions div:hover,
.graphOptions div:focus,
.YcolumnOptions div:focus {
  transform: scale(1.005);
  z-index: 999;
  background: rgb(215, 61, 50);
  color: $light;
  opacity: 0.7;
}

.graphOptions-title i,
.columnOptions-title i {
  right: 10px;
  top: 15px;
  cursor: pointer;
  position: absolute;
}

.ti-angle-double-right {
  color: #ef937e;
}

.dsboard-chart__resize {
  background-color: rgba(244, 117, 96, 0.3);
  border: 1px solid rgba(244, 117, 96, 0.2);
  border-radius: 6px;
  content: 'Agrandar o achicar';
}

.dsboard-chart__resize--left,
.dsboard-chart__resize--right,
.dsboard-chart__resize--top,
.dsboard-chart__resize--bottom {
  position: absolute;
  z-index: 3;
  width: 14px;
  height: 14px;
  border-radius: 100%;
  background-color: #ef937e;
}

.dsboard-chart.dsboard-chart--resize {
  .dsboard-chart__settings--button {
    &.ti-arrows-corner {
      font-size: 20px;
      right: 4px;
      position: absolute;
      top: 8px;
      color: rgba(244, 117, 96, 1);

      &:before {
        content: '\e646';
      }

      &:hover {
        transition: 0.9s;
        transform: rotate(90deg);
      }
    }

    &.ti-bar-chart,
    &.ti-menu,
    &.ti-angle-left {
      display: none !important;
    }
  }

  .dsboard-chart__settings {
    background-color: transparent;
  }

  .dsboard-chart__settings--button {
    color: black;
  }

  .ti-menu {
    display: none;
  }
}

.sidenav {
  height: 100%;
  width: 240px;
  position: fixed;
  left: 0 !important;
  padding: 0;
  z-index: 999;
  background: #333;

  .head-sidebar {
    padding: 10px 0px 0px;
    height: 60px;

    .client-brand {
      img {
        height: 30px;
        display: block;
        margin: auto;
      }
    }

    .ti-back-left {
      display: block;
    }
  }

  .sidenav_menu {
    height: 100%;
    overflow-y: scroll !important;
    padding-bottom: 100px;

    &::-webkit-scrollbar {
      width: 5px !important;
      height: 7px !important;
      transition: all 0.2s;
    }
  }

  .br-sideleft-menu {
    position: relative;
    margin: 0px;
    padding: 0px;

    &.first_level_menu {
      margin: 10px 0px;
    }

    .menu_item {
      position: relative;
      width: 240px;

      .item_option {
        display: block;
        align-items: center;
        padding: 8px 0px 8px 15px;
        color: #ffffff;
        transition: all 0.2s ease-in-out;
        font-size: 14px;
        width: 100%;
        line-height: 26px;
        cursor: pointer;
        position: relative;

        &.item_option_active{
          font-weight: 800;
        }

        &:hover {
          background-color: #3a4d5c;

          [icono]:before {
            color: $light;
          }
        }

        span {
          text-align: left;
          display: table-cell;
          vertical-align: middle;
        }
      }

      .spand_menu {
        color: $secondary;
        font-size: 19px;
        cursor: pointer;
        font-weight: 500;
        line-height: 45px;
        position: absolute;
        top: 0px;
        right: 5px;
        -webkit-transition: all 0.3s ease;
        transition: all 0.3s ease;
      }

      [icono]:before {
        color: $secondary;
        font-family: 'Font Awesome 5 Free';
        font-weight: 600;
        font-style: normal;
        line-height: 25px;
      }

      [icono]:not(.fas):before {
        content: '\f111';
      }

      .menu_icon {
        width: 25px;
        text-align: left;
        display: table-cell;
        vertical-align: middle;
        font-size: 16px;
      }

      .menu_children .br-sideleft-menu {
        position: relative;
        margin: 0px;
        display: none;
        background: #333;

        .item_option {
          padding: 0px 10px 0px 30px;
          font-size: 13px;

          &:hover {
            text-decoration: none;
            color: $light;
            font-weight: 600;
            background-color: #3a4d5c;
          }

          &.active {
            background-color: #1d5c87;
            color: #fff;
            border-radius: 3px;
          }
        }

        .spand_menu,
        .menu_icon {
          font-size: 6px;
          width: 15px;
        }
      }

      &.active {
        .spand_menu {
          -webkit-transform: rotateZ(90deg);
          transform: rotateZ(90deg);
        }

        .br-sideleft-menu {
          display: block;

          .spand_menu {
            display: none;
          }
        }
      }
    }
  }
}

.sidenav-close {
  .sidenav {
    width: 50px;
    transition: all 0.7s;
  }

  .client-brand {
    display: none;
  }

  .spand_menu {
    opacity: 0;
  }

  .br-sideleft-menu .menu_item.active .br-sideleft-menu {
    display: none;
  }

  .br-sideleft-menu .menu_item .item_option span {
    opacity: 0;
  }

  #container {
    padding: 0px 0px 0px 50px !important;
    transition: all 0.7s;
  }

  .sidenav_menu:hover {
    background-color: $terciary;
    -webkit-transition: width 1s ease-in-out;
    -moz-transition: width 1s ease-in-out;
    -o-transition: width 1s ease-in-out;
    transition: width 0.3s ease-in-out;
    width: 240px;

    .spand_menu {
      opacity: 1;
    }

    .br-sideleft-menu .menu_item.active .br-sideleft-menu {
      display: block;
    }

    .br-sideleft-menu .menu_item .item_option span {
      opacity: 1;
      transition: all 0.3s ease-in-out;
    }
  }

  .sidenav_menu {
    width: 50px;
    transition: width 0.3s;
    overflow: hidden;
  }
}

div.sidenav>div.sidenav_menu>ul.br-sideleft-menu.menu_level_1>li.menu_item>a {
  display: inline-block;
  width: 90%;
}

div.sidenav>div.sidenav_menu>ul.br-sideleft-menu.menu_level_1>li.menu_item>a+span.item_option {
  display: inline-block;
  width: 10%;
  top: 0px;
  padding: 21px 0px 20px 15px;
}

.ant-tabs-content {
  .contenedorPb>div>.templateTest {
    >.col-xl-7 {
      padding-left: 0 !important;
      padding-right: 0 !important;
      padding-right: 15px !important;
    }

    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  h2 {
    margin-top: 30px;
    font-weight: 300;
    border-left: 3px solid #1d5c87;
    padding-left: 10px;
    margin-bottom: 35px;
    font-size: 25px;
  }
}

.ant-tabs-nav .ant-tabs-tab.ant-tabs-tab-active:hover {
  color: white !important;
}

.ant-tabs-content .ui.form {
  padding: 0 15px 15px !important;
  max-width: 100% !important;

  label {
    display: none;
  }
}

.filtro {
  transform: rotate(90deg);
  background: #3a4d5c;
  color: $light;
  display: block;
  height: 20px;
  width: 42px;
  position: absolute;
  left: -11px;
  top: 11px;
  font-size: 12px;
  padding: 1px 7px;
}

.no-mostrar .select {
  margin-bottom: 0;
  background-color: transparent !important;
  left: 0 !important;
  margin-right: -11px;

  >div {
    >div {
      padding: 0 !important;

      >div {
        padding: 0 !important;
        height: 24px;
        border-radius: 0 !important;
      }

      position: relative;
      top: -7px;
      left: -2px;
    }

    height: 24px;
    background-color: transparent !important;
  }
}

#filtersContainer.no-mostrar .filtro {
  transform: rotate(0deg);
  height: 24px;
  width: 48px;
  left: 0;
  top: 0px;
  padding: 3px 10px;
  display: block;
  position: relative;
  float: left;
}

.desagregado {
  padding-left: 23px !important;
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}

.dsboard .ti-angle-left {
  background: #ef937e;
  font-weight: 400;
  border-radius: 50%;
  color: #fff;
  line-height: 36px;
  width: 36px;
  height: 36px;
  text-align: center;
  position: absolute;
  top: 60px;
  right: 10px;
  z-index: 99999;
  cursor: pointer;
}

@media (max-width: 991px) {
  #container {
    margin-left: 0 !important;
  }

  .sidenav {
    left: -240px !important;
    transition: left 0.3s linear;

    .head-sidebar i.ti-back-left {
      display: none;
    }
  }

  .ti-back-right {
    display: none !important;
  }

  .navbar .oi-menu {
    color: $primary;
    padding-right: 15px;
    padding-left: 10px;
    font-size: 16px;
    position: relative;
    top: -1px;
    cursor: pointer;
    display: block;
  }

  .oi-x {
    float: right;
    display: block;
    padding-right: 14px;
    padding-top: 1px;
    cursor: pointer;
  }

  #container {
    padding-left: 0px !important;
  }

  #dashboardTemplate {
    .badge {
      white-space: inherit !important;
      text-align: left;
      width: 178px;
      font-size: 17px;
      padding-top: 5px !important;
    }

    .col-md-6 {
      flex: 0 0 100%;
      max-width: 100%;
    }

    .Box.SummaryBox {
      height: 95px;

      .SummaryBox-value {
        font-size: 67px;
      }
    }
  }
}

@media (max-width: 991px) {
  .dsboard-chart {
    width: 100% !important;
  }

  .dsboard-chart__settings--button.ti-arrows-corner {
    display: none;
  }
}

.leaflet-marker-icon .fa-sort-up {
  color: #f25b69 !important;
  font-size: 19px !important;
  left: 1px;
  position: relative;
}

.leaflet-div-icon {
  border: 0;
  background: transparent;
}

.dashboards .select {
  float: left;
  margin-top: 0;
  left: 21px;
  background-color: #fff6e5;

  .css-1thnurl {
    padding: 3px 6px;
  }

  .css-27dzu2 {
    display: none;
  }
}

ul.directions {
  margin: 0px 15px 0px;
  padding: 0px;
  overflow-y: scroll;
  max-height: 438px;

  @include scrollbar;

  li {
    padding: 10px 0px 15px;
    cursor: pointer;
    color: #000;
    font-weight: 300;
    border-bottom: dashed 1px #ddd9;

    &.active {
      color: #1890ff;
      font-weight: 600;
    }

    &:hover {
      color: #333333;
    }
  }
}

#datagrid-total-footer>div>table>tbody>tr td {
  display: block;
}

#datagrid-total-footer>div>table>tbody>tr {
  display: flex;
  overflow-x: scroll;
}

.dsboard-chart.list .dx-datagrid-headers .dx-datagrid-table .dx-row.dx-column-lines.dx-header-row>td {
  border-radius: 0 !important;
}

.dashboards .select>div {
  background-color: #fff6e5;
  border-radius: 0 !important;
  border-bottom-left-radius: 4px !important;
  border-bottom-right-radius: 4px !important;
  cursor: text;
  padding: 0px 0 0px !important;
  margin-top: 0 !important;
  border: 0 !important;
  box-shadow: 0 0 0 !important;

  >div {
    background-color: transparent !important;
  }
}

.no-mostrar {
  .nuevo input {
    display: none;
  }

  .select {

    svg[height='20'],
    span {
      display: none;
    }
  }
}

.filtro-title {
  float: left;
  padding-top: 13px;
  height: 48px;
  background: #ef937e;
  color: $light;
  width: 110px;
  text-align: center;
  font-size: 13px;
  position: relative;
  left: 21px;
}

.dashboards .select {
  input[type='text'] {
    width: 250px !important;
    background-color: #fff !important;
    height: 25px !important;
    padding: 8px 0 0 !important;
    border: 1px solid rgba(33, 37, 41, 0.4) !important;
  }

  svg {
    color: #212529 !important;
    cursor: pointer;

    &[height='14'] {
      color: white !important;
    }
  }
}

.dsboard-chart {
  &:not(.list) .ti-write {
    display: none;
  }

  &.list {
    &.permanent {
      max-height: calc(100% - 151px) !important;
      margin-top: 15px !important;
      overflow-y: auto;
      position: fixed !important;
      width: calc(100% - 21%) !important;
      height: auto !important;
      z-index: 1000 !important;
      overflow-y: hidden !important;

      >span {
        border-bottom: 0 !important;
      }

      .ant-tabs {
        padding: 3px 20px 10px !important;
        margin-top: -32px !important;
        max-width: 100% !important;

        .ant-tabs {
          margin-top: 10px !important;
        }

        .gridContainer {
          padding-bottom: 10px !important;
        }

        .ant-tabs {
          padding-top: 40px;
        }
      }
    }

    .ant-tabs [role='tablist'] {
      display: none;
    }

    &.permanent .ant-tabs [role='tablist'] {
      display: block;
    }
  }

  td.dx-command-edit.dx-command-edit-with-icons.dx-cell-focus-disabled.dx-datagrid-drag-action {
    display: none;
  }

  td.dx-command-edit.dx-command-edit-with-icons {
    display: none !important;
  }

  td.dx-command-edit.dx-command-edit-with-icons.dx-hidden-cell.dx-cell-focus-disabled {
    display: none !important;
  }

  tr.dx-row.dx-data-row.dx-row-lines.dx-column-lines td:first-child {
    display: none !important;
  }

  tr.dx-row.dx-data-row.dx-row-lines.dx-column-lines td:nth-child(2) {
    display: none;
  }

  td.dx-first-cell {
    display: none;
  }

  td.dx-command-edit.dx-command-edit-with-icons.dx-hidden-cell.dx-cell-focus-disabled {
    display: none;
  }

  td.dx-cell-focus-disabled.dx-datagrid-drag-action:nth-child(2) {
    display: none;
  }
}

.permanent .chart_title {
  display: none;
}

.edicionlist {
  font-size: 12px;
  font-weight: 500;
  padding: 6px 8px;
  border-radius: 6px;
  background-color: #ef937e;
  color: $light;
  position: relative;
  top: 1px;
}

.dsboard-chart {
  &.list {
    &.permanent .ant-tabs [role='tabpanel'] {
      margin-top: 0;
    }

    overflow: hidden;

    .ant-tabs .contenedorPb {
      margin: 0 !important;
      box-shadow: 0 0 0 !important;
      width: 100% !important;
      padding-left: 0 !important;
      padding-right: 0 !important;
      overflow: hidden !important;
      padding: 9px 0 !important;
    }
  }

  .ti-save {
    display: none;
  }

  &.list {
    &.permanent {
      .ant-tabs .contenedorPb {
        width: calc(100% - 40px) !important;
      }

      .ti-save {
        display: block;
        color: #212529;
        font-size: 16px;
        background-color: white !important;
        margin-top: -5px;
        margin-right: 0px;
        position: relative !important;
        right: 15px;
        top: 25px;
      }
    }

    [role='tabpanel'] {
      margin-top: 0;
    }

    &.permanent {
      [role='tabpanel'] {
        margin-top: 15px;
      }

      .ti-write {
        display: none;
      }
    }

    .ti-bar-chart {
      display: none;
    }

    .dx-icon-comment:before,
    .dx-datagrid .dx-link {
      pointer-events: none;
      visibility: hidden;
    }

    &.permanent {

      .dx-icon-comment:before,
      .dx-datagrid .dx-link {
        pointer-events: auto;
        visibility: visible;
      }
    }
  }
}

.agrandar {
  position: absolute;
  z-index: 3;
  width: 30px;
  height: 30px;
  border-radius: 100%;
  background-color: #36d2a7;
  top: calc(50% - 40px);
  right: -20px;
  cursor: pointer;
}

.achicar {
  position: absolute;
  z-index: 3;
  width: 30px;
  height: 30px;
  border-radius: 100%;
  background-color: #36d2a7;
  top: calc(50% - 40px);
  right: -20px;
  cursor: pointer;
  top: calc(50% + 40px);
}

.agrandar i,
.dsboard-chart__resize .achicar i {
  font-size: 16px !important;
  color: $light;
  position: relative;
  top: 6px !important;
  left: 7px !important;
  background-color: transparent !important;
  font-weight: 400 !important;
  line-height: 14px !important;
}

.dashboard-options {
  position: absolute;
  right: 30px;
  top: -40px;
}

#filtersContainer {
  &.mostrar {
    display: block !important;
    box-shadow: 0 0 0 !important;
    background-color: #fff6e5 !important;
    z-index: 99999;
    position: relative;

    .nuevo {
      display: block !important;
      box-shadow: 0 0 0 !important;
    }
  }

  &.no-mostrar {
    background-color: #fff6e5 !important;
    float: left;
    box-shadow: 0px 1px 15px 1px rgba(62, 57, 107, 0.07) !important;
    z-index: 10000;
    position: absolute;
    top: 90px;
    left: 0;
    right: 0;
  }
}

.no-mostrar .filtro-title {
  padding: 3px 17px;
  width: auto;
  height: 24px;
  left: 0;
}

#filtersContainer {
  &.no-mostrar .nuevo {
    background-color: transparent !important;
    padding: 0 !important;
    height: 24px !important;
    display: inline-block;
    width: auto;
  }

  &.mostrar {
    .nuevo {
      height: 48px !important;
    }

    >div {
      position: relative;
      background-color: #fff6e5 !important;
      padding: 0 !important;
      width: 50% !important;
    }

    .filtro {
      width: 48px;
      left: -13px;
      top: 14px;
    }
  }
}

[onClick] {
  cursor: pointer;
}

[activo='no-black'] {
  color: #5abfae !important;
  font-weight: 600 !important;
  display: none;
}

[activo='black'] {
  display: none;
}

.geo .ti-arrows-corner,
.data .ti-arrows-corner,
.calendar .ti-arrows-corner {
  display: none;
}

.dsboard-chart {
  .dx-datagrid-header-panel {
    display: none;
  }

  &.list {
    &.permanent .dx-datagrid-header-panel {
      display: block;
    }

    .dx-datagrid-search-panel.dx-textbox.dx-texteditor input:not(.select2-offscreen):not(.select2-default):not(.select2-input):not([type='checkbox']):not([type='radio']):not(.select2-search__field) {
      border: 0 !important;
      color: white !important;
      border-radius: 6px !important;
      outline: 0 !important;
      height: 33px !important;
      margin-top: 1px;
    }
  }
}

.dx-datagrid-headers .dx-datagrid-content .dx-datagrid-table .dx-clear-button-area .dx-row.dx-datagrid-filter-row input:not(.select2-offscreen):not(.select2-default):not(.select2-input):not([type='checkbox']):not([type='radio']):not(.select2-search__field) {
  margin-top: 5px !important;
  border-bottom: 1px solid #ef937e !important;
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  padding-left: 23px !important;
  font-size: 14px !important;
}

.gridContainer {
  .dx-datebox-calendar .dx-dropdowneditor-input-wrapper {
    margin-top: 5px !important;
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    padding-left: 23px !important;
    font-size: 14px !important;
    border-radius: 0 !important;
    margin-bottom: 5px;
  }

  .dx-highlight-outline {
    .dx-datebox-calendar .dx-dropdowneditor-input-wrapper {
      padding-left: 0px !important;
      padding-right: 0px !important;
      height: 30px !important;
      border-bottom: 0 !important;
      border-bottom-left-radius: 4px !important;
      border-bottom-right-radius: 4px !important;
      margin-top: 0 !important;

      input {
        padding-top: 5px !important;
        height: 30px !important;
      }
    }

    .dx-dropdowneditor-button.dx-button-mode-contained {
      background-color: #fffdda !important;

      &:hover,
      &:focus {
        background-color: #fffdda !important;
      }
    }
  }
}

.file-manager-empty {
  .file-manager-btn--upload {
    width: 50px;
    height: 50px;
  }

  min-height: 150px !important;
}

.file-manager-empty__title {
  font-size: 15px !important;
  margin-bottom: 4px !important;
}

.dsboard-chart.list {
  .dx-datagrid-headers table .dx-datebox-calendar .dx-dropdowneditor-input-wrapper {
    margin-top: 5px !important;
  }

  .dx-command-edit-with-icons {
    color: transparent !important;
    background-color: transparent !important;

    &:before {
      color: transparent !important;
      background-color: transparent !important;
    }
  }

  &.permanent .dx-command-edit-with-icons {
    color: inherit !important;
    background-color: inherit !important;

    &:before {
      color: inherit !important;
      background-color: inherit !important;
    }
  }

  .dx-datagrid-rowsview .dx-command-edit-with-icons {
    >* {
      pointer-events: none !important;
    }

    pointer-events: none !important;
  }
}

.dx-datagrid-headers .dx-datagrid-content .dx-datagrid-table .dx-row.dx-datagrid-filter-row>td.dx-command-edit-with-icons {
  width: 1px !important;
  max-width: 1px !important;
  min-width: 1px !important;
}

.dsboard-chart.list {
  .gridContainer {
    margin-top: 0 !important;
  }

  &.permanent {
    .gridContainer {
      margin-top: 15px !important;
    }

    .dx-datagrid-rowsview .dx-command-edit-with-icons {
      >* {
        pointer-events: auto !important;
      }

      pointer-events: auto !important;
    }
  }
}

.dx-datagrid-rowsview .dx-row-focused.dx-data-row:not(.dx-edit-row) {
  > {

    td,
    tr>td {
      border-top: 0px solid white !important;
      border-bottom: 0px solid white !important;
    }

    td:not(.dx-focused),
    tr>td:not(.dx-focused) {
      background-color: #eee !important;
      color: #212529 !important;
    }
  }

  .dx-command-edit .dx-link {
    background-color: #eee !important;
    color: #212529 !important;
  }
}

.dx-overlay-content .dx-toolbar-after {
  top: 0 !important;
}

.dsboard-template .dsboard-charts {
  width: 100%;
}

@media (min-width: 1200px) and (max-width: 1399px) {
  .streamline .sl-item {
    .sl-content {
      font-size: 12px;
      padding: 5px 7px;
    }

    padding-bottom: 5px;
  }

  #dashboard_graph .sl-item.b- .sl-content {
    height: 47px;
    font-size: 15px;
    padding-top: 9px;

    span {
      font-size: 13px;
    }
  }
}

@media (max-width: 991px) {
  .dsboard-chart {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .dsboard-template .dsboard-charts {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }

  #dashboard_graph {

    .ti-id-badge,
    .tipo-dato,
    .ti-bar-chart-alt,
    .ti-layout-list-thumb {
      display: none;
    }

    .sl-item.b- .sl-content {

      &:before,
      i {
        font-size: 13px;
      }

      height: 44px;

      span {
        font-size: 13px;
        left: 43px;
        top: 12px;
      }

      .ti-close {
        top: 15px;
        right: 15px;
      }
    }

    padding-top: 27px;
  }

  .dashboardNew .streamline {
    .sl-content span {
      font-size: 12px;
    }

    .sl-item {
      padding-bottom: 3px;
    }
  }
}

@media (max-width: 767px) {
  .navbar ul {
    border-right: 0 !important;
    padding-right: 0;
    margin-right: 0;
  }
}

@media (max-width: 500px) {
  .dashboardNew {
    .ti-view-list-alt {
      display: none;
    }

    .ti-filter {
      right: 32px;
    }
  }
}

.collection {
  background: #fff;
  padding: 10px 20px;
  border: 1px solid #f0f0f0;
  border-radius: 10px;

  .ant-list-item {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 12px 0;
    color: rgba(0, 0, 0, 0.85);
    flex-direction: column;
  }

  .ant-list-item-meta-content {
    flex: 1 0;
    width: 90%;
    color: rgba(0, 0, 0, 0.85);
  }

  .ant-list-item-meta-title {
    margin-bottom: 4px !important;
  }

  .ant-list-item-content {
    width: 100%;
    padding-left: 50px;
  }
}

.col-12.col-xl-12.templateTest div h4 {
  padding-bottom: 15px !important;
  padding-top: 15px;
  font-weight: 300;
  font-size: 18px !important;
}

.file-manager {
  margin-left: 0;
  margin-right: 0;
  border-radius: 6px;
  border: 0;
  margin-bottom: 15px;
  padding: 13px 19px 18px !important;
  background-color: #1d5c8724;
}

.dx-dropdowneditor-input-wrapper {
  background-color: #eee;
  border-radius: 4px;
}

.ui.google.plus.mini.button {
  margin-bottom: 15px;
}

.ant-tabs-left .ant-tabs-content .form-group.form-check {

  .col-xl-6,
  .col-xl-4 {
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }
}

@media (min-width: 992px) and (max-width: 1200px) {
  .dsboard-chart {
    margin: 10px !important;

    &.data .sb-avatar__text {
      width: 60px !important;
      height: 60px !important;
    }
  }

  .dsboard-template .dsboard-charts .dsboard-chart--width-2.data {
    width: 30.6% !important;
  }

  .dsboard-chart.data .sb-avatar__text {
    width: 60px !important;
    height: 60px !important;
  }
}

.contenedorPb .design_724 .select-divwin input:not(.select2-offscreen):not(.select2-default):not(.select2-input):not([type='checkbox']):not([type='radio']):not(.select2-search__field) {
  height: auto !important;
  padding: 0px 0 0 !important;
}

.ui.form label {
  display: none;
}

.contenedorPb>.templateTest.col-12.col-xl-12 .design_724>.templateTest.col-12.col-xl-12 #detalle-right button.danger {
  margin-left: 0 !important;
}

.dsboard-chart__resize--top,
.dsboard-chart__resize--left {
  display: none !important;
}

.contenedorPb {

  .dashboards .nuevo.reductor,
  .homes .dashboards .nuevo.reductor {
    display: none !important;
  }

  .dashboardNew {

    .ti-layout-sidebar-left,
    .ti-filter {
      top: 60px;
      right: 38px;
      position: absolute;
    }
  }

  .homes .dashboardNew {

    .ti-layout-sidebar-left,
    .ti-filter {
      top: -40px;
      right: 10px;
      position: absolute;
    }
  }
}

.contenedorPb {
  .design_724.row {
    width: 100%;
  }

  .contenedorPb {
    padding: 0 !important;
    background-color: transparent !important;
    width: 100% !important;
    margin: 0 !important;
    min-height: 150px;
    float: left;
    border-radius: 0 !important;
    box-shadow: 0 0 0 #b9b9b9 !important;
    height: auto !important;
    overflow-y: auto;
  }
}

.dx-dialog.dx-overlay {
  .dx-overlay-content.dx-popup-normal .dx-dialog-message {
    font-size: 15px;
    font-family: 'Open Sans', sans-serif;
    color: #212529;
  }

  .dx-toolbar-center {
    width: 100% !important;
  }

  .dx-overlay-content {
    .dx-popup-bottom .dx-button {
      padding: 0 !important;
      width: 134px;
    }

    .dx-button-has-text .dx-button-content {
      width: auto !important;
      height: 34px;
      padding-top: 7px;
      background-color: #ef937e !important;
      border-radius: 6px;
      transition: all 0.3s ease-in-out;

      .dx-button-text {
        font-family: 'Open Sans', sans-serif;
        font-weight: 400;
        padding-top: 2px;
      }
    }
  }

  .dx-popup-bottom {
    &.dx-toolbar {
      height: 62px;
      padding-bottom: 5px;
    }

    .dx-button {
      background-color: transparent !important;
    }
  }

  >div {
    background-color: rgba(60, 72, 86, 0.8);
  }
}

.dx-datagrid {
  color: #212529 !important;
  font-family: 'Open Sans', sans-serif;
  opacity: 1;
  font-size: 12px;
  margin-top: 12px;
}

.dx-pager .dx-pages {
  .dx-info {
    color: #212529 !important;
    font-family: 'Open Sans', sans-serif;
    opacity: 1;
    font-size: 12px;
    margin-top: 12px;
  }

  .dx-navigate-button {
    margin-top: 7px;
  }
}

.dx-page-size {
  margin-top: 6px;
  font-family: 'Open Sans', sans-serif !important;
}

.dx-pager {

  .dx-pages .dx-selection,
  .dx-page-sizes .dx-selection {
    background-color: #ef937e;
    color: $light;
  }

  .dx-pages .dx-page {
    padding: 3px 7px;
  }
}

.design_724.row.templateTest.white .col-12.col-xl-12.templateTest {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.dx-datagrid.dx-datagrid-borders>.dx-datagrid-pager {
  border-top: 0;
  padding-bottom: 0;
  margin-top: 8px;
}

.permanent .dx-datagrid.dx-datagrid-borders>.dx-datagrid-pager {
  padding-bottom: 13px;
}

.dx-context-menu.dx-has-context-menu.dx-widget.dx-visibility-change-handler.dx-collection.dx-datagrid {
  margin-top: 0 !important;
}

.dx-pager .dx-pages {

  .dx-prev-button,
  .dx-next-button {
    font-size: 16px;
  }

  .dx-prev-button:before,
  .dx-next-button:before {
    color: #ef937e;
  }
}

.dsboard-chart {
  &.list .gridContainer {
    max-height: 394px;

    .dx-datagrid-pager {
      display: none;
    }
  }

  &.permanent {
    &.list .gridContainer {
      .dx-datagrid-pager {
        display: block;
      }

      max-height: calc(100vh - 260px);
      height: 100% !important;
    }

    >span span {
      border-bottom: 0;
    }
  }
}

.dx-error-row {
  display: none !important;
}

.contenedorPb {
  .design_724.row {
    padding: 0 !important;
    margin: 0 !important;
    position: static !important;

    >.templateTest {
      padding: 0 !important;
      margin: 0 !important;
      position: static !important;

      >div>.templateTest {
        padding: 0 !important;
        margin: 0 !important;
        position: static !important;
        padding-left: 15px !important;
      }
    }
  }

  .tablecontainer {
    border-top: 3px solid $terciary;
  }

  >.templateTest.col-12.col-xl-12 {

    &.videocontainer,
    &.tablecontainer {
      padding: 20px 20px 15px !important;
    }
  }

  .divcontainer.col-xl-6,
  .detalle-tab.col-xl-6 {
    flex: 0 0 calc(50% - 20px);
    max-width: calc(50% - 20px);
    margin-right: 10px;
    margin-left: 10px;
  }

  .divcontainer.col-xl-12 {
    flex: 0 0 calc(100% - 20px);
    max-width: calc(100% - 20px);
    margin-right: 10px;
    margin-left: 10px;
  }
}

.formespecifico .templateTest.divcontainer.col-xl-12,
.repeater {
  flex: 0 0 calc(100% - 20px);
  max-width: calc(100% - 20px);
  margin-right: 10px;
  margin-left: 10px;
  padding-left: 15px !important;
  padding-right: 15px !important;
}

[role='tabpanel'] .divcontainer>.formelement.col-12.col-xl-12 {
  margin: 0 !important;
  padding: 0 5px !important;
}

.divcontainer>.formelement.col-xl-6 {
  margin: 0 !important;
  padding: 0 7px !important;
}

[role='tabpanel'] .divcontainer>.formelement.col-12 {

  &.col-xl-4,
  &.col-xl-3,
  &.col-xl-2,
  &.col-xl-5 {
    margin: 0 !important;
    padding: 0 7px !important;
  }
}

.divcontainer>.formelement {
  &.col-12 {

    &.col-xl-4,
    &.col-xl-5,
    &.col-xl-3,
    &.col-xl-2 {
      margin: 0 !important;
      padding: 0 7px !important;
    }
  }

  &.col-xl-9,
  &.col-xl-1 {
    margin: 0 !important;
    padding: 0 7px !important;
  }

  &.col-xl-6 {
    flex: 0 0 50% !important;
    max-width: 50% !important;
  }

  &.col-xl-4 {
    flex: 0 0 33.33333333% !important;
    max-width: 33.33333333% !important;
  }

  &.col-xl-1 {
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }

  &.col-xl-3 {
    flex: 0 0 25% !important;
    max-width: 25% !important;
  }

  &.col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  &.col-xl-2 {
    flex: 0 0 16.66666666% !important;
    max-width: 16.66666666% !important;
  }

  &.col-xl-5 {
    flex: 0 0 41.66666667% !important;
    max-width: 41.66666667% !important;
  }
}

.contenedorPb {
  .detalle-tab .divcontainer.col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%;
    margin-right: 0;
    margin-left: 0;
  }

  .divcontainer {
    background-color: #f5f6fa !important;
    margin-top: 20px;

    &.col-xl-6 .divcontainer.col-xl-6 {
      flex: 0 0 50%;
      max-width: 50%;
      margin-right: 0;
      margin-left: 0;
    }

    >.divcontainer {
      border: 0 !important;
      box-shadow: 0 0 0;
      padding: 0 8px !important;
      margin-bottom: 0;
    }
  }

  .detalle-tab>.divcontainer {
    border: 0 !important;
    box-shadow: 0 0 0;
    padding: 0 8px !important;
    margin-bottom: 0;
  }

  fieldset .divcontainer {
    border: 0 !important;
    box-shadow: 0 0 0;
    padding: 0 8px !important;
    margin-bottom: 0;

    &.col-xl-6 {
      flex: 0 0 50%;
      max-width: 50%;
      margin-right: 0;
      margin-left: 0;
    }

    &:hover {
      box-shadow: 0 0 0 !important;
    }
  }

  .detalle-tab>.divcontainer {
    padding: 0 !important;
    background: transparent;
  }

  .tablecontainer {
    th:nth-child(2) {
      visibility: visible !important;
    }

    .table {
      thead {
        background-color: #111 !important;

        th {
          color: white !important;
        }
      }

      th,
      td {
        padding-left: 15px !important;
        padding-right: 15px;
      }

      thead th:first-of-type,
      tbody td:first-of-type {
        width: auto !important;
        display: table-cell !important;
      }
    }
  }

  .divcontainer>.col-xl-12 {
    padding: 0 8px !important;
  }

  .detalle-tab {

    .divcontainer>.col-xl-12,
    >.divcontainer>.col-xl-6 {
      padding: 0 8px !important;
    }
  }
}

.div-repeat>.col-xl-6 {
  padding: 0 8px !important;
}

.contenedorPb .divcontainer> {

  .col-xl-3,
  .col-xl-4,
  .col-xl-8 {
    padding: 0 8px !important;
  }
}

fieldset> {

  .col-xl-2,
  .col-xl-4 {
    padding: 0 8px !important;
  }
}

.detalle-tab {

  .col-xl-3,
  .col-xl-4 {
    padding: 0 8px !important;
  }
}

.contenedorPb .divcontainer>.col-xl-6,
.detalle-tab>.col-xl-6 {
  padding: 0 8px !important;
}

.contenedorPb .detalle-tab .form-group {
  padding: 0 8px !important;
  padding: 0 8px !important;
}

.divcontainer .form-group,
.div-repeat .form-group,
fieldset .form-group {
  padding: 0 8px !important;
}

.contenedorPb {

  .divcontainer .divcontainer>.col-xl-12,
  fieldset .divcontainer.col-xl-6 {
    padding: 0 !important;
  }

  select,
  textarea {
    height: 40px;
    width: 100%;
    background-color: #f8f9fa !important;
    border: 1px solid #dee2e6 !important;
    border-radius: 4px;
    box-shadow: 0 0 0;
    outline: 0 !important;
    font-family: 'Open Sans', sans-serif !important;
    padding: 7px 10px !important;
    font-size: 14px !important;
    color: #666666 !important;
    text-rendering: optimizeLegibility;
  }
}

input[type='date'],
.time input[type='text'] {
  height: 40px;
  width: 100%;
  background-color: #f8f9fa !important;
  border: 1px solid #dee2e6 !important;
  border-radius: 4px;
  box-shadow: 0 0 0;
  outline: 0 !important;
  font-family: 'Open Sans', sans-serif !important;
  padding: 7px 10px !important;
  font-size: 14px !important;
  color: #666666 !important;
  text-rendering: optimizeLegibility;
}

input {

  &[type='datetime-local'],
  &[type='month'] {
    height: 40px;
    width: 100%;
    background-color: #f8f9fa !important;
    border: 1px solid #dee2e6 !important;
    border-radius: 4px;
    box-shadow: 0 0 0;
    outline: 0 !important;
    font-family: 'Open Sans', sans-serif !important;
    padding: 7px 10px !important;
    font-size: 14px !important;
    color: #666666 !important;
    text-rendering: optimizeLegibility;
  }
}

.dx-texteditor-input-container input[type='text'],
.contenedorPb input[type='text'].form-control,
input[type='password'],
.not-logged input[type='text'],
input[type='number'],
textarea {
  height: 40px;
  width: 100%;
  background-color: #ffffff !important;
  border: 1px solid #dee2e6 !important;
  border-radius: 4px;
  box-shadow: 0 0 0;
  outline: 0 !important;
  font-family: 'Open Sans', sans-serif !important;
  padding: 7px 10px !important;
  font-size: 14px !important;
  color: #666666 !important;
  text-rendering: optimizeLegibility;
}


.contenedorPb {
  .divLoaderCSS{
    position: absolute;
    left: 50%;
    top: 50%;
  }

  .loaderText{
  width: fit-content;
  font-weight: bold;
  font-size: 14px;
  clip-path: inset(0 100% 0 0);
  animation: l5 2s steps(20) infinite;
  }
  .loaderText:before {
    content:"Cargando..."
  }
  @keyframes l5 {to{clip-path: inset(0 -1ch 0 0)}}

  .loaderCSS {
    /* HTML: <div class="loader"></div> */
  width: 10px;
  aspect-ratio: 1;
  background: #25b09b;
  box-shadow: 0 0 60px 15px #25b09b;
  transform: translate(-10px);
  clip-path: inset(0);
  animation:
    l4-1 0.5s ease-in-out infinite alternate,
    l4-2 1s   ease-in-out infinite;
  @keyframes l4-1 {
    100% {transform: translateX(80px)}
  }
  @keyframes l4-2 {
     33% {clip-path: inset(0 0 0 -100px)}
     50% {clip-path: inset(0 0 0 0)     }
     83% {clip-path: inset(0 -100px 0 0)}
  }
  }

  .input[data-tipo='datetime'] input,
  input[type='email'] {
    height: 40px;
    width: 100%;
    background-color: #f8f9fa !important;
    border: 1px solid #dee2e6 !important;
    border-radius: 4px;
    box-shadow: 0 0 0;
    outline: 0 !important;
    font-family: 'Open Sans', sans-serif !important;
    padding: 7px 10px !important;
    font-size: 14px !important;
    color: #666666 !important;
    text-rendering: optimizeLegibility;
  }

  .dx-texteditor-buttons-container {
    background-color: #f8f9fa !important;
    border: 1px solid #dee2e6 !important;
    border-left: 0 !important;
  }
}

.dx-texteditor-input-container .dx-placeholder,
.contenedorPb .input[data-tipo='datetime'] input::placeholder {
  color: hsl(0, 0%, 50%) !important;
  padding-left: 5px !important;
  font-family: 'Open Sans' !important;
  letter-spacing: normal !important;
  font-size: 15px !important;
}

.time input[type='text']::placeholder {
  color: hsl(0, 0%, 50%) !important;
  padding-left: 5px !important;
  font-family: 'Open Sans' !important;
  letter-spacing: normal !important;
  font-size: 15px !important;
  padding-left: 1 !important;
}

.contenedorPb .input[data-tipo='datetime'] input::placeholder {
  padding-left: 1px !important;
  position: relative;
  top: -2px !important;
}

.select-divwin {
  background-color: #ffffff !important;
  border: 1px solid #dee2e6 !important;
  border-radius: 4px;

  >div {
    border-width: 0 !important;
    outline: 0 !important;

    &:hover {
      border-width: 0 !important;
      outline: 0 !important;
    }

    >div>div {
      margin-top: -1px !important;
    }
  }
}

.contenedorPb {
  .select-divwin input {
    border: 0 !important;
  }

  .form-control.dx-datebox {
    margin-bottom: 0 !important;
  }

  .dx-texteditor-input {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }
}

.dx-state-readonly input.dx-texteditor-input {
  border: 0 !important;
  border-top: 1px solid #dee2e6 !important;
}

.dx-state-readonly .dx-texteditor-buttons-container {
  border: 0 !important;
}

.detalle-tab {
  border-top: 3px solid #ef937e;
  padding: 26px 20px 15px;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.03);
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  background-color: rgba(223, 229, 235, 0.4);
  margin-bottom: 20px;

  &:after {
    font-weight: 300 !important;
    content: 'Detalle';
    font-size: 14px !important;
    padding: 6px 12px 7px !important;
    border-radius: 0px;
    left: 15px;
    top: -19px;
    float: left;
    box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.14), 0 7px 10px -7px #ef937e;
    background: -webkit-linear-gradient(30deg, #ef937e, #ef937e);
    background: linear-gradient(60deg, #ef937e, #ef937e);
    position: absolute;
    color: $light;
  }
}

.repetir .ti-plus {
  padding-right: 5px;
  position: relative;
  top: 1px;
  font-size: 12px;
  font-weight: 600;
}

.div-repeat {
  padding: 0 22px 15px !important;
}

.buttoncontainer {
  padding-left: 13px !important;
}

#submit-button,
.btn-success,
.steps-action button {
  border: 0;
  transition: all 40ms linear;
  margin-bottom: 15px;
  padding: 12px 25px;
  font-size: 16px;
  line-height: 1.33;
  background-color: #1d5c87;
  font-family: 'Open Sans';
  border-radius: 6px;
  height: auto !important;
  color: white !important;
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.14), 0 7px 5px -5px #1d5c87;
}

#submit-button {
  &:active:focus {
    -moz-outline-style: none;
    outline: medium none;
    color: #fff;
    background-color: #1a4670 !important;
    border-color: #1a4670 !important;
  }

  &:focus {
    &:hover {
      -moz-outline-style: none;
      outline: medium none;
      color: #fff;
      background-color: #1a4670 !important;
      border-color: #1a4670 !important;
    }

    -moz-outline-style: none;
    outline: medium none;
    color: #fff;
    background-color: #1a4670 !important;
    border-color: #1a4670 !important;
  }
}

.btn-success {

  &:hover,
  &:focus {
    -moz-outline-style: none;
    outline: medium none;
    color: #fff;
    background-color: #1a4670 !important;
    border-color: #1a4670 !important;
  }
}

#confirmacion {
  border-radius: 6px;
  background: $light;
  padding: 20px 30px 10px;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.14);
  margin-bottom: 15px;
}

#confirma-text {
  padding-bottom: 12px;
}

#confirmacion button {
  border: 0;
  transition: all 40ms linear;
  margin-bottom: 15px;
  padding: 11px 18px;
  font-size: 15px;
  line-height: 1.33;
  font-family: 'Open Sans';

  i {
    padding-right: 7px;
    font-size: 14px;
  }
}

#confirmo {
  background-color: #1d5c87;
}

#cancelo {
  background-color: #ef937e;
}

.ant-tabs {
  background: $light;
  padding: 0 15px 0 15px !important;
  width: 100%;
  margin-bottom: 15px;
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: calc(100% - 26px);
  max-width: calc(100% - 26px);
  margin-right: 13px;
  margin-left: 13px;
  border-top: 4px solid $light;
  margin-top: 10px;
  min-height: calc(100vh - 146px);
  height: auto;
  -webkit-box-shadow: 0px 1px 15px 1px rgba(69, 65, 78, 0.08);
  box-shadow: 0px 1px 15px 1px rgba(69, 65, 78, 0.08);
  background-color: #fff;
  overflow: auto !important;
  padding-top: 10px !important;
  padding-bottom: 30px !important;

  .ant-tabs-top-content>.ant-tabs-tabpane {
    min-height: calc(100vh - 248px) !important;
    height: auto;
  }
}

.ant-tabs-bar {
  border-bottom: 3px solid #eee;
  margin: 0;
  padding-top: 15px;
  width: calc(100% - 15px);
}

.ant-tabs {
  .ant-tabs {
    border-top: 0;
    padding: 0 !important;
    flex: 0 0 100%;
    max-width: 100%;
    margin-right: 0;
    margin-left: 0;
  }

  &.ant-tabs-card>.ant-tabs-bar .ant-tabs-nav-container {
    height: auto !important;
  }
}


.ant-tabs-tabpane .templateTest.col-xl-12.form-group {
  padding: 0 8px !important;
}

.ant-tabs {
  .ant-tabs-left-bar {
    width: 200px;
    margin-top: 13px;
    
  }

  .ant-tabs-left-content {
    width: calc(100% - 200px) !important;
    padding-left: 20px !important;
    border-left: 1px solid #e8e8e8;
    margin-top: 3px !important;
  }
}

.contenedorPb input.form-control:focus {
  color: #495057;
  background-color: #fff !important;
  border-color: #80bdff !important;
  outline: 0 !important;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25) !important;
}

.notification-container-top-right {
  right: 26px;
  top: 26px;
}

.notification-content * {
  font-family: 'Open Sans';
}

.file-manager-row:nth-child(even) {
  background-color: #eee;
}

.file-manager-row__icon {
  width: 22px;
  margin-right: 15px;
}

.file-manager-header,
.file-manager-row {
  padding: 8px 15px;
}

.file-manager-header__item {
  font-weight: 500;
}

.file-manager-btn {
  border: 0;
  outline: 0;

  &:focus {
    border: 0;
    outline: 0;
  }
}

.file-manager-nav {
  padding: 7px 15px;
  border-top-left-radius: 8px !important;
  border-top-right-radius: 8px !important;
}

.file-manager-empty {
  border-bottom-left-radius: 8px !important;
  border-bottom-right-radius: 8px !important;
}

.file-manager-nav--right button.ant-btn.ant-btn-icon-only {
  background-color: transparent !important;
}

.divcontainer .gridContainer {
  width: 100%;
  height: 350px !important;
  padding: 3px 0 13px !important;
}

.ant-tabs {
  .ant-tabs .gridContainer {
    width: 100%;
    height: 350px !important;
    padding: 3px 0 13px !important;
  }

  .gridContainer {
    width: 100% !important;
  }
}

.gridContainer {
  .dx-texteditor-input-container input {
    border-radius: 0 !important;
  }

  .dx-datagrid-search-panel .dx-texteditor-input-container input[type='text'] {
    height: 33px !important;
  }

  /* .dx-datagrid-header-panel .dx-texteditor-input-container input[type="text"] {
    border-bottom: 0 !important;
  } */

  .dx-datebox.dx-textbox.dx-texteditor.dx-datebox-date.dx-datebox-calendar {
    height: auto !important;
  }

  .dx-texteditor-buttons-container {
    background-color: #eee !important;
    border: 0 !important;
    margin: 5px 0 !important; // boton cancelar editado
  }

  .dx-highlight-outline {
    .dx-texteditor-buttons-container {
      border: 0 !important;
      background-color: #fffdda !important;
    }

    .dx-texteditor-input-container input[type='text'] {
      border: 0 !important;
      background-color: #fffdda !important;
      font-size: 14px !important;
    }
  }
}

.form-check-input {
  height: 40px;
  margin-bottom: 0;
}

.ant-checkbox-checked::after {
  width: auto !important;
  height: auto !important;
  border: 1px solid #1d5c87 !important;
}

.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus+.ant-checkbox-inner {
  border-color: #1d5c87;
}

.ant-checkbox-inner {
  top: 5px;
  left: 18px;
  width: 38px;
  height: 38px;

  &::after {
    top: 46%;
    left: 29%;
    width: 10px;
    height: 19.142857px;
  }

  top: -23px;
  left: 0px;
  width: 39px;
  height: 39px;
  background-color: #f8f9fa;
  cursor: pointer;
}

.form-check-input {
  margin-left: 0;
}

.ant-tabs {

  .divcontainer,
  .repeater,
  fieldset {
    margin-bottom: 32px !important;
  }
}

.design_724.row>.templateTest.divcontainer {
  margin-bottom: 32px !important;
}

.ant-tabs .ant-tabs-left-bar {
  .ant-tabs-tab {
    text-align: left;
    font-size: 14px;
    font-family: 'Open Sans';
    border-bottom: 0;
    padding: 9px 17px 7px 0;
    width: 98%;
    margin-left: 2px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    margin-bottom: 5px;
  }

  .ant-tabs-tab-active.ant-tabs-tab {
    border-right: 0 !important;
    color: #ef937e !important;
    font-size: 15px;

    &:hover {
      color: #1d5c87 !important;
    }
  }

  border-right: 0 !important;
}

[role='tabpanel'] {
  .templateTest.col-12.col-xl-12 {
    padding: 0 !important;

    &.divcontainer {
      padding: 28px 20px 15px !important;
    }
  }

  fieldset.templateTest.col-12.col-xl-12 {
    padding: 28px 20px 15px !important;
  }
}

.contenedorPb {

  [role='tabpanel'] .templateTest.col-12.col-xl-12.form-group,
  .design_724.row.templateTest .templateTest.col-12.col-xl-12.form-group {
    padding: 0 8px !important;
    margin-bottom: 1rem !important;
  }
}

[role='tabpanel'] [role='tabpanel'] {

  .templateTest.col-12.col-xl-12.divcontainer,
  fieldset.templateTest.col-12.col-xl-12 {
    padding: 28px 20px 15px !important;
  }
}

.ant-tabs-tabpane.ant-tabs-tabpane-inactive {
  display: none;
}

.divcontainer h4 {
  padding: 0 0 5px !important;
  margin-top: -5px !important;
}

.dsboard .ant-tabs {
  border-top: 0 !important;
}

.ant-tabs .ant-tabs [role='tabpanel'] {
  margin-top: 15px !important;
  padding-right: 15px;
}

.divcontainer,
fieldset,
.detalle-tab,
.file-manager,
.buttoncontainer button {
  transition: all 0.3s ease-in-out;
}

.buttoncontainer button {
  transition: all 1s ease-in-out;

  &:hover {
    transform: translateX(4px) scale(1.1);
    z-index: 999;
  }
}

.list:not(.permanent) .dx-row.dx-data-row.dx-row-lines.dx-column-lines td a:before {
  color: #999 !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.homes .dashboardNew {
  padding: auto 20px !important;
}

.dx-checkbox {
  outline: auto;
}

.rotate.ti-bar-chart {
  transform: rotate(90deg);
  display: block;
  position: absolute;
  left: -19px;
  top: 11px;
  font-size: 12px !important;
}

.progress {
  height: auto !important;
  background-color: transparent;
}

.templateTest .formelement {
  margin: 0.5%;
  float: left;
  padding: 0 7px 0;
  margin-top: 0px;
  min-height: 0px !important;

  label {
    font-weight: 600 !important;
    margin-bottom: 4px !important;
    font-size: 14px !important;
    display: inline-block;
  }
}

.form-hidden {
  display: none;
}

.react-notification-root {
  width: calc(100% - 240px);
}

.dx-popup-content input.dx-texteditor-input[inputmode='decimal'] {
  background: #fff !important;
}

.dx-toolbar.dx-widget.dx-visibility-change-handler.dx-collection.dx-popup-bottom.dx-popup-cancel.dx-popup-done .dx-toolbar-items-container {
  background: #fff;
}

.templateTest {
  &.col-xl-2 {
    -ms-flex: 0 0 15.66666666%;
    flex: 0 0 15.66666666%;
    max-width: 15.66666666%;
  }

  &.col-xl-3 {
    -ms-flex: 0 0 24%;
    flex: 0 0 24%;
    max-width: 24%;
  }

  &.col-xl-4 {
    -ms-flex: 0 0 32.33333333%;
    flex: 0 0 32.33333333%;
    max-width: 32.33333333%;
  }

  &.col-xl-5 {
    -ms-flex: 0 0 40.66666667%;
    flex: 0 0 40.66666667%;
    max-width: 40.66666667%;
  }

  &.col-xl-6 {
    -ms-flex: 0 0 49%;
    flex: 0 0 49%;
    max-width: 49%;
  }

  &.col-xl-7 {
    -ms-flex: 0 0 57.33333333%;
    flex: 0 0 57.33333333%;
    max-width: 57.33333333%;
  }

  &.col-xl-8 {
    -ms-flex: 0 0 65.66666667%;
    flex: 0 0 65.66666667%;
    max-width: 65.66666667%;
  }

  &.col-xl-9 {
    -ms-flex: 0 0 74%;
    flex: 0 0 74%;
    max-width: 74%;
  }

  &.col-xl-10 {
    -ms-flex: 0 0 82.33333333%;
    flex: 0 0 82.33333333%;
    max-width: 82.33333333%;
  }

  &.col-xl-11 {
    -ms-flex: 0 0 90.66666667%;
    flex: 0 0 90.66666667%;
    max-width: 90.66666667%;
  }
}

.divcontainer.detalle {
  border-top: 2px solid #e35d6a !important;
  background-color: rgba(223, 229, 235, 0.4);

  b {
    background: #ef937e !important;
    box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.14),
      0 7px 10px -5px rgba(223, 229, 235, 0.4);
  }
}

.dx-datagrid-column-chooser-mode-select .dx-toolbar-after .dx-button-mode-contained .dx-icon {
  color: #232e3b !important;
}

.ant-progress-inner {
  background-color: #ddd;
}

.dx-datagrid.dx-datagrid-borders>.dx-datagrid-pager {
  padding-bottom: 15px;
}

.uploader-info {
  margin: 20px 0px;
}



.barraprogreso label {
  display: block;
}

.ui.progress .bar>.progress {
  font-family: 'Open Sans', sans-serif !important;
  font-weight: 500 !important;
}

.informer h3.count {
  position: absolute;
  left: -123px;
  top: 6px;
  width: 1000px;
}

.alert-form {
  color: #dc3545;
  font-size: 12px;
  font-weight: 400;
  padding-top: 3px;
  height: 20px;
  display: block;
}

.contenedorPb {

  input[type='text'].required.form-control,
  textarea.required.form-control,
  input[type='number'].required.form-control {
    background-color: #f8d7da !important;
    border-color: #f5c6cb !important;
  }

  .dx-datebox.required.form-control {

    .dx-texteditor-input-container input[type='text'],
    .dx-texteditor-buttons-container {
      background-color: #f8d7da !important;
      border-color: #f5c6cb !important;
    }
  }

  .form-control.required.select-divwin {
    background-color: #f8d7da !important;
    border-color: #f5c6cb !important;
  }

  .required.form-control.time {
    input[type='text'] {
      background-color: #f8d7da !important;
      border-color: #f5c6cb !important;
    }

    margin-bottom: 8px;
  }

  .warning.form-control.time {
    margin-bottom: 8px;
  }

  input[type='text'].warning.form-control,
  textarea.warning.form-control,
  input[type='number'].warning.form-control,
  input[type='email'].warning.form-control {
    background-color: #fff3cd !important;
    border-color: #ffeeba !important;
  }

  .dx-datebox.warning.form-control {

    .dx-texteditor-input-container input[type='text'],
    .dx-texteditor-buttons-container {
      background-color: #fff3cd !important;
      border-color: #ffeeba !important;
    }
  }

  .warning.form-control.time input[type='text'] {
    background-color: #fff3cd !important;
    border-color: #ffeeba !important;
  }
}

.dx-datebox-datetime.dx-texteditor.dx-editor-outlined {
  border: 0 !important;
  width: 100% !important;
  height: 40px;
}

.dx-texteditor.dx-editor-outlined {
  background: #fff;
  border: 1px solid transparent;
  border-radius: 4px;
}

.time.icon {
  color: #ef937e !important;
  font-weight: 500 !important;
  opacity: 1 !important;
}

.dx-datebox .dx-texteditor-input-container input[type='text'] {
  border-right: 0 !important;
}

.ant-tabs-content.ant-tabs-content-animated.ant-tabs-top-content {
  margin-left: 0 !important;
}

.ant-checkbox-inner {
  outline: 0 !important;
  background-color: #f8f9fa !important;
  border: 1px solid #dee2e6 !important;
  border-radius: 4px !important;

  &:focus,
  &:hover {
    outline: 0 !important;
    background-color: #f8f9fa !important;
    border: 1px solid #dee2e6 !important;
    border-radius: 4px !important;
  }
}

.ant-checkbox-checked {
  .ant-checkbox-inner {
    background-color: #ef937e !important;
    border-color: transparent !important;
  }

  &::after {
    border: 0 solid #1d5c87 !important;
  }
}

.dx-datagrid-headers .dx-datagrid-content .dx-datagrid-table .dx-row.dx-datagrid-filter-row .dx-dropdowneditor-input-wrapper {
  background-color: transparent !important;
}

.gridContainer .dx-datagrid-headers .dx-datagrid-content .dx-datagrid-table .dx-row.dx-datagrid-filter-row .dx-dropdowneditor-input-wrapper .dx-texteditor-buttons-container {
  margin-top: 5px;
  height: 35px !important;

  .dx-dropdowneditor-button {
    border-radius: 0 !important;
  }
}

.dx-timeview {
  .dx-item-content.dx-box-item-content.dx-timeview-clock {
    margin-bottom: 10px;
  }

  .dx-texteditor-input-container .dx-texteditor-input {
    height: 34px !important;
    width: 30px !important;
    background-color: transparent !important;
    margin: 0 !important;
    padding: 4px 5px 4px 8px !important;
    color: #333 !important;
    font-size: 14px !important;
    border-radius: 4px !important;
    min-height: 24px !important;
    border: 0 !important;
  }
}

.dx-overlay-wrapper.dx-datebox-wrapper-datetime.dx-datebox-wrapper-calendar {
  .dx-toolbar-item .dx-toolbar-item-content .dx-datagrid-toolbar-button {
    top: 0;
    background-color: #ef937e !important;
    border-radius: 4px !important;
    height: auto !important;
    width: auto !important;
    font-weight: 400 !important;

    &:hover {
      top: 0;
      background-color: #ef937e !important;
      border-radius: 4px !important;
      height: auto !important;
      width: auto !important;
      font-weight: 400 !important;
    }
  }

  .dx-toolbar-after .dx-button {
    top: 0;
    background-color: #ef937e !important;
    border-radius: 4px !important;
    height: auto !important;
    width: auto !important;
    font-weight: 400 !important;
  }
}

.ant-steps-label-horizontal {
  padding: 0 10px 30px !important;
  margin-bottom: 5px !important;
}

.steps-action button {
  margin-left: 5px !important;
  margin-top: 10px !important;
}

.ant-steps-item-title {
  font-size: 15px !important;
}

.ant-tabs-vertical .ant-tabs .ant-tabs-left-bar .ant-tabs-tab {
  padding: 4px 17px 2px 0 !important;
}

.ant-tabs .ant-tabs-left-content {
  overflow-y: auto !important;
  padding-bottom: 20px !important;
}

.ant-steps-label-horizontal .ant-steps-item-icon span svg {
  position: relative;
  top: -9px;
  left: -1px;
}

.ant-steps-label-horizontal .ant-steps-item-icon span svg {
  position: relative;
  top: -9px;
  left: -1px;
}

.steps-content .ant-tabs-nav {
  display: none;
}

/* GENERAL */

html,
body {
  background: #f2f3f8;
}

body {
  overflow-y: auto;
}

.navbar {
  -webkit-box-shadow: 0 1px 15px 1px rgba(62, 57, 107, 0.07);
  box-shadow: 0 1px 15px 1px rgba(62, 57, 107, 0.07);
  margin-bottom: 0;
  border-radius: 0;
  background-color: $primary;
  padding: 8px 22px !important;
  display: flex;
  justify-content: space-between;

  .navbar-brand {
    font-size: 18px;
    font-weight: 600;
    padding: 0 !important;
    height: auto !important;
    color: $light;
    font-size: 18px !important;
    text-transform: uppercase;
    letter-spacing: 1.5px;
  }

  ul {
    margin: 4px 10px;
    border-right: 1px solid rgba(255, 255, 255, 0.7);
    padding: 7px 10px 0 0;

    .dropdown-menu {
      margin-top: 11px !important;
      background-color: $primary !important;
      border: 0 !important;
      box-shadow: 0 0 0;
      position: absolute;
      float: none;
      z-index: 4543545;
      min-width: 152px;
      padding-top: 0 !important;
      border-right: 3px solid $secondary !important;

      button {
        outline: 0;

        li {
          border-bottom: 1px dotted rgba(255, 255, 255, 0.5);
          padding: 8px;
          color: $light;
          cursor: pointer;

          i {
            color: $secondary;
            padding-right: 7px;
            font-weight: 900;
            font-size: 16px;
            position: relative;
            top: 2px;
          }
        }
      }
    }

    a {
      font-size: 14px;

      i {
        font-size: 16px;
        margin: 0 6px;
        color: white;
        cursor: pointer;
        line-height: 30px;
        width: 20px;
      }
    }
  }

  .username {
    color: $primary;
  }

  .userarea .dropdown-menu li {
    line-height: 30px;
    cursor: pointer;
  }

  .userarea .dropdown-menu {
    background-color: $primary !important;
  }

  .sb-avatar__text {
    border-radius: 500px;
    width: 34px !important;
    height: 34px !important;
    margin-top: -1px;

    div span {
      font-size: 15px;
      font-weight: 600;
    }
  }
}

.subheader {
  padding: 0px 30px;
  float: none;
  width: 100%;
  height: 70px;

  .subheader_content {
    h1 {
      font-size: 22px;
      letter-spacing: 0px;
      position: relative;
      font-weight: 600;
      color: #181a1b;
      line-height: 80px;
    }
  }
}

.contenedorPb {
  width: auto;
  margin: 0px 20px;
  min-height: 100vh;
  height: auto;
  display: block;

  >.templateTest.col-12.col-xl-12 {
    padding: 0px 0px 0px !important;
    flex: 0 0 100%;
    max-width: 100%;
  }

  >.ant-tabs {
    overflow-y: auto !important;
  }
}

.repeater,
.divcontainer,
.videocontainer,
.tablecontainer,
.file-manager {
  background: $light;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  padding: 25px 20px 15px;
  position: relative;
  float: left;
  margin-bottom: 30px;
  transition: all 0.6s ease-in-out;
  border-top: 3px solid $primary;
  box-shadow: $box-shadow;

  &.file-manager {
    width: 100% !important;
    border-radius: 0 !important;

    .file-manager-nav,
    .file-manager-header,
    .file-manager-rows {
      border: 0 !important;
      border-radius: 0 !important;
      padding: 10px 15px !important;

      &.file-manager-header {
        border-bottom: 0 !important;
        border-top: 0 !important;
        background-color: $background !important;

        .file-manager-header__item {
          color: $terciary !important;
        }
      }

      &.file-manager-rows {
        border-top: 0 !important;
        padding: 15px !important;
      }

      &.file-manager-nav {
        border-bottom: 0 !important;
        margin-bottom: -1px !important;
      }
    }
  }

  &.detalle {
    border-top: 3px solid $secondary !important;
    background-color: $background;

    b {
      background: $secondary !important;
    }
  }

  b {
    font-size: 16px !important;
    padding: 5px 12px !important;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    margin-top: -45px;
    margin-left: 7px;
    float: left;
    background: $primary;
    position: absolute;
    color: $light;
    width: auto !important;
    display: block;
  }

  label {
    font-weight: 400;
    margin-bottom: 4px;
    font-size: 13px;
  }
}

.repeater {
  position: relative;
  padding: 0 !important;
  border-top: 0 !important;
  box-shadow: 0 0 0 !important;
  margin-bottom: 0 !important;
  padding: 0px !important;

  #detalle-right {
    position: absolute;
    top: -4px;
    z-index: 0;

    .repetir,
    .repetir:hover,
    .repetir:focus {
      background-color: $primary !important;
      border: 0;
      padding: 7px 10px;
      font-size: 13px;
      z-index: 100;
      font-family: 'Open Sans';
      font-weight: 500;
      outline: 0;
      box-shadow: 0 0 0 !important;
    }

    .danger {
      background-color: $secondary !important;
      border: 0;
      padding: 7px 11px;
      z-index: 100;
      font-size: 13px;
    }
  }

  .duplicador {
    border-top: 0 !important;
    box-shadow: 0 0 0 !important;
    margin-bottom: 0 !important;
    padding: 20px 0 0px !important;

    &:first-child {
      display: none !important;
    }

    b {
      position: absolute;
      margin-top: -60px !important;
    }
  }
}

.btn-primary,
.steps-action button {
  background: $primary !important;
  border: 0 !important;
  box-shadow: $box-shadow;
  margin-left: -2px;
  font-weight: 400 !important;
  &:disabled{
    cursor: not-allowed;
    opacity: 0.5;
  }
}

/* TABLE */

.dx-datagrid-headers .dx-datagrid-content .dx-datagrid-table .dx-row>td {
  background-color: $terciary !important;
  padding: 7px 8px !important;
}

/*.dx-datagrid-content .dx-datagrid-table .dx-row .dx-command-edit.dx-command-edit-with-icons {
	border: 0 !important;
}*/

.dx-datagrid-headers .dx-datagrid-table {
  .dx-row.dx-datagrid-filter-row>td {
    padding: 2px 5px !important;
    background-color: $light !important;

    .dx-texteditor-input-container input[type='text'] {
      border: 0 !important;
      background-color: #eee !important;
      border-bottom: 1px solid $secondary !important;
      height: 35px !important;
      padding: 0px 15px 0 !important;
      margin-bottom: 5px !important;
    }
  }

  td {
    position: relative;

    .dx-datagrid-text-content {
      font-family: 'Open Sans', sans-serif !important;
      text-rendering: optimizeLegibility;
      -webkit-font-smoothing: antialiased;
      color: white !important;
      text-transform: uppercase;
    }

    .dx-column-indicators {
      position: relative;

      .dx-sort-up:before,
      .dx-sort-down:before {
        color: $secondary;
        font-size: 22px !important;
        top: -3px;
        right: 0px;
        position: absolute;
        font-weight: 600;
        font-family: 'Font Awesome 5 Free';
      }

      .dx-sort-down:before {
        content: '\f107';
      }

      .dx-sort-up:before {
        content: '\f106';
      }
    }
  }
}

.dx-toolbar-after .dx-texteditor-input-container input[type='text'],
.dx-toolbar-after .dx-texteditor-input-container input[type='text']:hover,
.dx-toolbar-after .dx-texteditor-input-container input[type='text']:focus {
  background-color: #fff !important;
  height: 35px !important;
  outline: 0 !important;
}

.dx-datagrid-content .dx-datagrid-table .dx-row>td,
.dx-datagrid-content .dx-datagrid-table .dx-row>tr>td {
  font-size: 11px;
  font-family: 'Open Sans', sans-serif !important;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  color: $terciary !important;
  font-weight: 400 !important;
  padding: 12px 8px 11px;
  border-left: 0 !important;
  border-right: 0 !important;
  //border-bottom: 1px solid rgba(33, 37, 41, 0.08) !important;
  vertical-align: middle;
}

//Eliminacion de lineas superpuestas en las tablas
tr.dx-row.dx-data-row.dx-row-lines.dx-column-lines {
  border-bottom: 1px solid rgba(33, 37, 41, 0.08) !important;
}

.dx-datagrid-content .dx-datagrid-table .dx-row>td {
  font-size: 12px;
}

.dx-datagrid-headers .dx-datagrid-content .dx-datagrid-table .dx-row>tr>td {
  font-size: 11px !important;
}

.dx-link {
  font-family: 'Font Awesome 5 Free';
  font-weight: 600;
  position: relative;
  font-size: 16px;
  transition: all linear 100ms;
}

/* TABS */

.ant-tabs.ant-tabs-card>.ant-tabs-bar .ant-tabs-tab {
  background-color: $light;
  color: #333;
  border: 1px solid $gray-light;
  line-height: normal;
  margin-right: 0;
  font-size: 14px;
  padding: 10px 18px 7px;
  font-weight: 400px !important;
  border-top-left-radius: 0px !important;
  border-top-right-radius: 0px !important;
  font-size: 15px;
  height: 40px;
  padding: 8px 15px 6px;
  line-height: 27px;
  border-bottom: 0;

  &.ant-tabs-tab-active {
    background-color: $primary;
    border: 1px solid $primary !important;
    color: $light;
  }

  >div {
    font-family: 'Open Sans' !important;

    .ant-tabs-close-x {
      color: $light;
      font-weight: 600;
    }
  }
}

.ant-tabs-ink-bar {
  background-color: $secondary;
  right: -1px !important;
  width: 5px !important;
}

.ant-tabs [type='submit'],
.ant-tabs [type='submit']:focus {
  margin-left: -5px !important;
  box-shadow: 0 0 0 !important;
  margin-bottom: 20px !important;
}

/* TABLE */

.gridContainer {
  width: 100%;
  background: $light;
  border-radius: 6px;

  .dx-toolbar-after {
    left: 0px !important;

    .dx-button {
      padding-top: 10px !important;
      border-radius: 500px;
      height: 32px !important;
      width: 28px !important;
      border: 0 !important;

      &:hover {
        background-color: transparent !important;
      }

      &[aria-label='remove'] {
        //background-color: $secondary !important;
      }

      &.dx-datagrid-addrow-button {
        //background-color: $primary !important;
      }

      &[aria-label='refresh'] {
        //background-color: $quintuary !important;
      }

      &[aria-label='export-excel-button'] {
        //background-color: $terciary !important;
      }

      &[aria-label='check'] {
        //background-
      }

      &[aria-label='Selector de Columnas'] {
        //background-color: $sextuary !important;
      }

      .dx-button-content {
        padding-top: 0;

        i {
          font-family: 'Font Awesome 5 Free' !important;
          font-size: 18px !important;
          color: $light !important;
          font-weight: 900 !important;

          &.dx-icon-export-excel-button:before {
            content: '\f56d' !important;
            position: relative;
            left: 1px;
            color: $terciary !important;
          }

          &.dx-icon-check:before {
            content: '\f058' !important;
            position: relative;
            left: -1px;
            color: green !important;
            font-size: 19px !important;
          }

          &.dx-icon-refresh:before {
            content: '\f2f1' !important;
            color: #c661b9 !important;
          }

          &.dx-icon-remove:before {
            content: '\f1f8' !important;
            color: $secondary !important;
          }

          &.dx-icon-edit-button-addrow:before {
            content: '\f067' !important;
            color: $primary !important;
          }

          &.dx-icon-column-chooser:before {
            content: '\f0db' !important;
            color: $sextuary !important;
          }
        }
      }
    }
  }

  .dx-toolbar-before {
    left: 120px;
    top: -24px;

    .dx-datagrid-group-panel .dx-group-panel-message {
      height: 35px !important;
      background-color: #eee !important;
      padding: 8px 15px !important;
      margin: 0 0 0 10px;
      border: 1px solid #ddd;
      border-radius: 4px;
      font-size: 13px !important;
    }
  }

  .dx-dialog.dx-overlay .dx-popup-wrapper>.dx-overlay-content {
    border: 0 !important;
    background-color: $terciary !important;
    border-radius: 6px;
  }

  .dx-overlay .dx-popup-content.dx-dialog-content {
    padding: 20px 20px 5px;
    background: $terciary !important;
    border: 0;
  }

  .dx-highlight-outline {
    font-size: 12px !important;
    padding: 5px 2px !important;

    &>div {
      background-color: #fffdda !important;
    }
  }
}

/* FORM */

.contenedorPb>.templateTest.col-12.col-xl-12.formsindetalle {
  padding: 0 15px !important;
}

/* CHECKBOX */

.form-control.ant-checkbox-wrapper {
  border: 0 !important;
  padding-left: 0 !important;
  float: left !important;
  width: auto !important;
  background: transparent !important;

  input[type='checkbox'],
  input[type='radio'] {
    width: 20px;
    height: 18px;
    top: 4px;
    position: relative;
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: $secondary !important;
    border-color: transparent !important;
  }
}

.dx-datebox-calendar .dx-dropdowneditor-icon:before {
  color: $secondary;
}

.leaflet-control-zoom.leaflet-bar.leaflet-control {
  z-index: unset;
}

.leaflet-top.leaflet-left {
  z-index: unset;
}

.leaflet-pane.leaflet-map-pane {
  z-index: unset;
}

div#mapa-block {
  z-index: unset;

  h4 {
    margin: 0 !important;
    padding: 20px 15px !important;
    font-weight: 500 !important;
  }

  h4.map-error {
    color: #ff4d4f;
    font-weight: 500 !important;
  }

  h4.map-searching {
    color: #1890ff;
    font-weight: 300 !important;
  }
}

.templateTest.col-12.formelement {
  z-index: unset;
}

/* STEP */

.steps-content {
  float: left;
  width: 100%;
  padding-right: 15px;
}

.ant-steps-label-horizontal {
  padding: 10px 15px 30px 0 !important;

  .ant-steps-item-process {
    .ant-steps-item-icon {
      background-color: $primary !important;
    }
  }

  .ant-steps-item-icon {
    font-weight: 800;

    i {
      position: relative;
      top: -3px;
    }
  }
}

.steps-action button {
  margin: 0 8px 0 10px !important;
}

hr {
  display: none;
}

.gridContainer .dx-datagrid-filter-row .dx-editor-cell .dx-editor-with-menu .dx-datebox-calendar {
  background-color: #eee !important;
  height: 35px !important;
  border-radius: 0 !important;
  padding-top: 0 !important;
  margin-top: -4px !important;
}

.gridContainer .dx-datagrid-filter-row .dx-editor-cell .dx-editor-with-menu .dx-datebox-calendar .dx-texteditor-buttons-container {
  margin-top: 0 !important;
}

.dx-datagrid-rowsview .dx-row-focused.dx-data-row>td:not(.dx-focused),
.dx-datagrid-rowsview .dx-row-focused.dx-data-row>tr>td:not(.dx-focused),
.dx-datagrid-rowsview .dx-row-focused.dx-data-row .dx-command-edit:not(.dx-focused) .dx-link {
  background-color: inherit !important;
}

.gridContainer .dx-editor-cell:not(.dx-datagrid-validator) .dx-highlight-outline {
  background-color: #fffdda !important;
}

.notification-container-top-right {
  width: 400px;
  top: 59px;
  right: 37px;

  .notification-item-root {
    width: 400px;
    margin-bottom: 5px;

    .notification-success {
      background-image: linear-gradient(120deg,
          #19692c 60%,
          #94d668 100%) !important;
    }

    .notification-danger {
      background-image: linear-gradient(120deg,
          #cb3234 60%,
          #ff726f 100%) !important;
    }

    .notification-message {
      font-size: 15px;
      padding: 8px 5px;
      font-weight: 5600;
      font-family: 'Montserrat', sans-serif;
    }
  }
}

.btnBuildPage {
  margin-right: 21px;
}

.checkboxwrapper .alert-form {
  display: block;
  margin-top: 40px;
}

.ant-tabs-nav .ant-tabs-tab-active,
.ant-tabs-nav .ant-tabs-tab-active:hover {
  color: $secondary;
}

.ant-tabs-ink-bar {
  background-color: $secondary;
  width: auto !important;
  height: 0 !important;
}

.ant-tabs .ant-tabs .ant-tabs-bar {
  padding-top: 0 !important;
}

.repeater {
  margin-left: 0 !important;
  margin-right: 0 !important;
  padding-top: 25px !important;
  flex-basis: 100% !important;
  max-width: 100% !important;

  &.col-12.col-xl-12 {
    padding: 0px 0px 40px !important;
  }

  #detalle-right {
    left: 8px !important;
    bottom: 0px;
    top: auto;
  }

  .templateTest.duplicador.divcontainer {
    margin: 0 !important;
    flex-basis: 100% !important;
    max-width: 100% !important;
    padding: 0 !important;
    padding-bottom: 20px !important;
    margin: 20px 0px 0px !important;
    border-bottom: solid 1px #c2c2c2 !important;
    border-radius: 0 !important;

    &:last-child {
      border-bottom: 0 !important;
    }
  }
}

.mdal {
  z-index: 5000 !important;
}

.mdal__container {
  background-color: $background;
  width: 60%;

  h1 {
    font-family: 'Poppins', sans-serif;
    font-weight: 800;
    font-size: 30px;
    margin: 5px 10px 35px;
  }

  .repeater {
    margin-left: 0 !important;
    margin-right: 0 !important;
    padding-top: 25px !important;
    flex-basis: 100% !important;
    max-width: 100% !important;

    #detalle-right {
      right: 10px;
    }

    .templateTest.duplicador.divcontainer {
      margin-left: 0 !important;
      margin-right: 0 !important;
      flex-basis: 100% !important;
      max-width: 100% !important;
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
  }
}

.dx-dialog.dx-overlay .dx-overlay-content .dx-button-has-text .dx-button-content:hover {
  background-color: $secondary;
  transform: scale(1.02);
}

.ant-steps-label-horizontal {
  padding: 0 15px 25px 0 !important;

  .ant-steps-item-active .ant-steps-item-icon {
    background-color: transparent !important;

    span {
      color: $secondary !important;
    }
  }

  .ant-steps-item-icon {
    border: 0 !important;
    width: 8px;

    span {
      padding-top: 9px;
      display: block;
    }
  }
}

[role='tabpanel'] .formespecifico .templateTest.col-12.col-xl-12.formespecifico {
  padding-left: 10px !important;
  padding-right: 10px !important;
}

@media (min-width: 1200px) and (max-width: 1399px) {

  .file-manager-header__item .file-manager-row__item {
    font-size: 13px;
  }

  .navbar {
    padding: 2px 20px !important;
    height: 48px;

    ul {
      margin: 3px 0 3px 10px;

      &.ml-auto {
        border-right: 0 !important;
      }

      &#hola {
        padding: 3px 0 3px 15px !important;
        border-left: 1px solid $light;
      }

      a i {
        font-size: 16px;
      }
    }
  }

  .contenedorPb {
    width: auto;
    margin: 0px 5px !important;
  }

  .ant-tabs .ant-tabs .ant-tabs-bar {
    padding-top: 0 !important;
    border-bottom: 5px solid #eee;
    margin-bottom: 5px !important;
  }

  .btn-primary,
  .steps-action button {
    font-size: 14px;
    padding: 10px 15px !important;
    margin-top: -5px !important;
    border-radius: 4px !important;
    line-height: 1 !important;
    margin-bottom: 25px !important;
  }

  .btnBuildPage {
    margin-right: 18px;
  }

  .repeater,
  .divcontainer,
  .videocontainer,
  .tablecontainer,
  .file-manager {
    border-top-width: 2px !important;
  }
}

/* CARDS */

.infinite-scroll-component {
  .d-flex.justify-content-center {
    padding: 50px 0px;
  }
}

.toggle-view-btn {
  position: absolute;
  right: 0px;
  top: -60px;
  height: 30px;
  line-height: 20px;
  box-shadow: none !important;

  &:focus {
    box-shadow: none !important;
  }
}

.card {
  transition: all 0.5ms;

  .dd-menu.dd-menu-left {
    position: absolute;
    right: 0px;
    top: 5px;
    overflow: visible;
    width: 20px;
    z-index: 99999;
  }

  .dd-menu.dd-menu-left button {
    border: 0;
    background-color: transparent;
  }

  .dd-items-left {
    position: absolute;
    right: 0;
  }

  .card-link {
    color: #e35d6a;
  }

  .overlaytrigger {
    position: absolute;
    left: 10px;
    top: 5px;
    padding: 3px;
    cursor: pointer;
    font-size: 17px;
    z-index: 999;
  }

  .icon-list {
    position: absolute;
    right: 0px;
    bottom: 0px;

    i {
      font-size: 27px;
      padding: 6px;
    }
  }

  .card-body {
    position: relative;
    padding-top: 37px;

    &+.card-body {
      padding-top: 20px;
    }
  }
}

.card.blanco,
.card.white {
  background-color: #00000014 !important;
}

.rojo {
  background-color: #f37183;
}

.violaceo {
  background-color: #ca95a9;
}

.amarillo {
  background-color: #fff9b4;
}

.salmon {
  background-color: #ffe0ca;
}

.naranja {
  background-color: #ffaa9a;
}

.lila {
  background-color: #f3d9ea;
}

.celeste {
  background-color: #daf0f8;
}

.blanco {
  background-color: white;
}

.beige {
  background-color: #fff9e4;
}

.verde-musgo {
  background-color: #8ca395;
}

.verde-claro {
  background-color: #e3fbe3;
}

.verde {
  background-color: #e3fbe3;
}

.verde-azulado {
  background-color: #698285;
}

.gris-medio {
  background-color: #bbbbbb;
}

.gris-claro {
  background-color: #e9e9e9;
}

.card.white {
  background-color: #cfcfcf4d;
}

.colorItem {
  cursor: pointer;

  i {
    font-size: 28px;
  }
}

.dx-datagrid-rowsview .dx-select-checkboxes-hidden>tbody>tr>td>.dx-select-checkbox,
.dx-datagrid-rowsview .dx-select-checkboxes-hidden>tbody>tr>td>.dx-select-checkbox:hover,
.dx-datagrid-rowsview .dx-select-checkboxes-hidden>tbody>tr>td>.dx-select-checkbox:focus {
  display: block !important;
  border: 0 !important;
  outline: 0 !important;

  .dx-checkbox-icon,
  .dx-datagrid-checkbox-size .dx-checkbox-icon {
    weight: 17px !important;
    height: 17px !important;
    border-color: $secondary !important;
  }
}

.dx-checkbox.dx-state-readonly .dx-checkbox-icon {
  border-color: #b4b4b4 !important;
  background-color: #fff;
}

.informer h3.count {
  position: relative !important;
  left: 0 !important;
  top: -18px !important;
  width: auto !important;
  display: block !important;
  float: left !important;
}

.fa-file-pdf {
  font-size: 24px;
  position: absolute;
  top: 9px;
  right: 16px;
  color: $secondary;
  cursor: pointer;
}

@media (min-width: 1200px) and (max-width: 1400px) {

  .dsboard-chart.pie,
  .dsboard-chart.petal {
    width: 47% !important;
  }
}

.dx-scrollbar-horizontal {
  top: 0 !important;
  bottom: auto !important;
}

.dx-datagrid-content .dx-datagrid-table .dx-row.dx-freespace-row .dx-command-edit.dx-command-edit-with-icons {
  border: 0 !important;
}

.dx-loadpanel-content-wrapper {
  border: 0 !important;
}

.dx-loadpanel-message {
  background: $light;
  padding: 10px;
}

.btn.btn-outline-primary.btn-primary {
  background-color: transparent !important;
  color: $primary !important;
  border: 1px solid $primary !important;
  margin-bottom: 30px !important;
  transition: all linear 150ms;
}

.btn.btn-outline-primary.btn-primary:hover,
.btn.btn-outline-primary.btn-primary:focus {
  background-color: $primary !important;
  color: white !important;
  border: 1px solid $primary !important;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
}

.dx-loadpanel div * {
  font-size: 15px !important;
  font-family: 'Open Sans', sans-serif !important;
  animation: fadeIn 1.5s infinite alternate;
  color: $terciary !important;
}

.dx-loadindicator-icon-custom {
  background: transparent !important;
}

.dx-datagrid-bottom-load-panel {
  border-top: 0 !important;
  padding-bottom: 1px !important;
}

.dx-datagrid-content-fixed .dx-datagrid-bottom-load-panel {
  display: none !important;
}

.contenedorPb input.form-control[readonly],
.contenedorPb input.form-control[readonly]:hover,
.contenedorPb input.form-control[readonly]:focus {
  background-color: #ffffff !important;
  outline: 0 !important;
  pointer-events: none !important;
  box-shadow: 0 0 0 !important;
  border-color: #ffffff !important;
  cursor: none !important;
  padding: 0px 0px 0px 10px !important;
  border-top: solid 1px #d4d4d5 !important;
  border-radius: 0;
  font-size: 12px !important;
}

.mdal__back {
  cursor: pointer;
}

#mapa-block {
  border: 1px solid #dee2e6;
  padding: 0px;
  border-radius: 4px;
  margin-left: 0px;
  width: 100%;
  background-color: #f8f9fa;
  margin-bottom: 20px;
  margin: 0px;
  z-index: 1;
  position: relative;

  &.cnfrm {
    background-color: #e2f6fe !important;
    border-color: #e2f6fe !important;
  }

  .select {
    margin-bottom: 20px;
  }

  h2 {
    font-family: 'Poppins', sans-serif;
    font-weight: 900;
    font-size: 28px;
    margin-top: 7px;
    margin-bottom: 12px !important;

    i {
      font-size: 32px !important;
      padding-left: 10px !important;
      color: $sextuary !important;
    }
  }

  button {
    margin-right: 10px;
    border: 0 !important;
    box-shadow: 0 0.46875rem 2.1875rem rgba(4, 9, 20, 0.03),
      0 0.9375rem 1.40625rem rgba(4, 9, 20, 0.03),
      0 0.25rem 0.53125rem rgba(4, 9, 20, 0.05),
      0 0.125rem 0.1875rem rgba(4, 9, 20, 0.03) !important;
    -webkit-transition-duration: 1.2s;
    /* Safari */
    transition-duration: 1.2s;

    &:hover {
      opacity: 0.9;
      font-weight: 500;
    }

    &.btn-secondary {
      background-color: $secondary !important;
    }
  }

  p {
    font-size: 14px;
    line-height: 21px;
  }
}

.ant-tabs .gridContainer {
  height: 550px !important;
}

.dx-datagrid-summary-item {
  font-weight: 700;
  font-size: 15px;
}

#tablet-block {
  position: relative;
  text-align: right;

  &.col-xl-12 {
    .leaflet-container {
      width: 100% !important;
    }
  }

  .leaflet-container {
    position: relative;
    width: 100%;
    height: 524px;
    border-radius: 4px;
    border: 4px solid white;
    box-shadow: 0 0.46875rem 2.1875rem rgba(4, 9, 20, 0.03),
      0 0.9375rem 1.40625rem rgba(4, 9, 20, 0.03),
      0 0.25rem 0.53125rem rgba(4, 9, 20, 0.05),
      0 0.125rem 0.1875rem rgba(4, 9, 20, 0.03);

    &.grey {
      filter: grayscale(100%);
      cursor: none;
    }

    img {
      filter: saturate(1.9) contrast(85%);
    }

    .leaflet-bar {
      box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2) !important;
      border-radius: 0 !important;
      position: relative;

      a.leaflet-draw-edit-edit {
        border-radius: 0 !important;
        border-bottom: 0 !important;
        background-image: none !important;
        background-color: $secondary !important;
        width: 80px !important;

        &:before {
          content: 'Editar';
          font-family: 'Open Sans', sans-serif !important;
          color: white !important;
        }
      }
    }

    .leaflet-control-attribution {
      display: none !important;
    }
  }
}

.leaflet-right .leaflet-draw-actions {
  right: 0px;

  li a {
    background-color: $secondary !important;
    border-radius: 0 !important;

    &[title='Guardar los cambios'] {
      background-color: $primary !important;
    }
  }
}

// DetailsDiv
.menuContainer {
  border: 1px solid #ddd;
  max-height: 200px;
  min-height: 40px;
  border-radius: 4px;
  overflow: auto;
}

.menuContainer::-webkit-scrollbar {
  width: 8px; /* Anchura del scrollbar */
  height: 4px;
}

.menuContainer::-webkit-scrollbar-thumb {
  background-color: #80878bcc !important; /* Color del scrollbar */
  border-radius: 4px; /* Borde redondeado del scrollbar */
}

.menuContainer::-webkit-scrollbar-track {
  background-color: transparent; /* Color de fondo del scrollbar */
}

.menuList {
  list-style: none;
  padding: 0;
  margin: 0;
}

.menuItem {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  border-bottom: 1px solid #ddd;
  cursor: pointer;
  color: #666666;;
  font-size: 14px !important;
  font-family: "Open Sans", sans-serif !important;
}

.menuItem:hover {
  background-color: #f0f0f0;
}

.menuIcon {
  margin-right: 10px;
}

.spinnerContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
}

// Modal
.ant-modal-wrap {
  z-index: 9999 !important;
}

@media (min-width: 1200px) and (max-width: 1500px) {
  #mapa-block {
    h2 {
      font-size: 23px;
    }

    button {
      font-size: 12px;
    }

    p {
      font-size: 12px;
      line-height: 18px;
    }
  }

  .leaflet-container {
    height: 360px !important;
  }
}

@media (min-width: 1600px) {
  .ant-tabs .gridContainer {
    height: 1000px !important;
  }
}

.ant-steps.ant-steps-horizontal.ant-steps-label-horizontal+.steps-content .ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-nav-wrap {
  display: none;
}

.templateTest.col-12.formelement.col-xl-3 {
  min-height: 90px;
}

button#getModelo {
  display: none;
}

.mdalback {
  color: white;
  font-size: 24px;
  cursor: pointer;
}

.ant-notification-notice {
  z-index: 9999999 !important;
}

.ant-notification.ant-notification-topRight {
  z-index: 99999999 !important;
}

.ant-tabs-tab+.ant-tabs-tab {
  margin: 0;
}

div#update-app-zone {
  position: fixed;
  left: 15px;
  width: 300px;
  height: 90px;
  top: auto;
  bottom: 15px;

  .ant-message-custom-content.ant-message-info {
    cursor: pointer;
    width: 300px;
  }
}

.capitalize {
  text-transform: capitalize !important;
}

.user-dropdown-trigger-zone {
  cursor: pointer;
}

.user-dropdown {
  width: 330px;
  z-index: 1000;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
  box-shadow: 0px 0px 50px 0px #523f6926;

  .separator {
    border-bottom: 1px solid #ebedf3;
  }

  .user-dropdown--header {
    border-top-left-radius: 0.42rem !important;
    border-top-right-radius: 0.42rem !important;
    padding: 1rem !important;
    align-items: center !important;
    display: flex !important;
    display: flex;

    .name {
      font-size: 18px;
      font-weight: 600;
      width: 150px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .profile {
      padding: 3px 15px;
      border-radius: 8px;
    }
  }

  .user-dropdown--options {
    padding-top: 10px !important;

    .navi-item {
      padding: 0 1rem;
      display: block;
      list-style: none;
    }

    .navi-link {
      display: flex;
      padding: 10px;
      align-items: flex-start;
    }

    .navi-text {
      color: #3f4254;
      width: 100%;

      .font-weight-bold {
        display: flex;

        &.ant-dropdown-trigger {
          cursor: pointer;
        }

        .ant-dropdown-link {
          margin: 0px 5px;
        }
      }
    }

    .navi-icon i::before {
      font-size: 20px;
      color: $primary;
    }

    .navi-footer {
      display: flex;
      justify-content: flex-start;
      padding: 1rem !important;
    }
  }

  .dark-theme-switch {
    margin-right: 10px;
  }
}

@each $palettename,
$palette in $palettes {
  body.#{$palettename} {
    background: palette-color($palette, 'background', 'base');
  }

  .#{$palettename} {
    .navbar {
      background-color: palette-color($palette);

      .navbar-brand {
        color: palette-color($palette, 'background', 'light');
      }

      .username {
        color: palette-color($palette, 'background', 'light');
      }

      .userarea .dropdown-menu {
        background-color: palette-color($palette) !important;
      }

      .ti-back-right {
        color: palette-color($palette, 'background', 'light');
      }
    }

    .loaderCSS {
      background: palette-color($palette) !important;
    }

    .user-dropdown--header {
      .profile {
        background: palette-color($palette, 'primary', 'light');
        color: palette-color($palette, 'background', 'light');
        opacity: 0.8;
      }

      .ant-switch-checked {
        background-color: palette-color($palette, 'primary', 'light');
      }
    }

    .user-dropdown .user-dropdown--options .navi-icon i::before {
      color: palette-color($palette, 'primary', 'light');
    }

    .btn-primary,
    .steps-action button {
      background: palette-color($palette) !important;
    }

    .repeater #detalle-right .repetir,
    .repeater #detalle-right .repetir:hover,
    .repeater #detalle-right .repetir:focus {
      background: palette-color($palette) !important;
    }

    .btn.btn-light-primary {
      background: palette-color($palette, 'primary', 'light');
      color: palette-color($palette, 'background', 'light');
    }

    #getModelo,
    .upload-btn-wrapper .btn {
      background: palette-color($palette) !important;
    }

    .dx-datebox-calendar .dx-dropdowneditor-icon:before {
      color: palette-color($palette, 'secondary') !important;
    }

    .form-control.ant-checkbox-wrapper .ant-checkbox-checked .ant-checkbox-inner {
      background: palette-color($palette, 'secondary') !important;
    }

    .dx-datagrid-headers .dx-datagrid-content .dx-datagrid-table .dx-row.dx-datagrid-filter-row input:not(.select2-offscreen):not(.select2-default):not(.select2-input):not([type='checkbox']):not([type='radio']):not(.select2-search__field) {
      border-bottom: 1px solid palette-color($palette, 'secondary') !important;
    }

    .dx-show-clear-button .dx-clear-button-area{
      border-bottom: 1px solid palette-color($palette, 'secondary') !important;
    }
    .dx-editor-with-menu .dx-dropdowneditor-input-wrapper {
      border-bottom: 1px solid palette-color($palette, 'secondary') !important;
    }

    .repeater,
    .divcontainer,
    .videocontainer,
    .tablecontainer {
      background: $light2;
      border-top: 3px solid palette-color($palette, 'secondary');

      &.detalle {
        border-top: 3px solid palette-color($palette, 'secondary') !important;

        b {
          background: palette-color($palette, 'secondary') !important;
        }
      }

      b {
        background: palette-color($palette, 'secondary');
        color: palette-color($palette, 'background', 'light');
      }
    }

    .navbar .oi-menu {
      color: palette-color($palette, 'background', 'light');
    }

    .sidenav {
      background: palette-color($palette, 'foreground', 'light');

      .sidenav_menu {
        background: palette-color($palette, 'foreground', 'light');
      }

      .head-sidebar {
        background-color: palette-color($palette, 'foreground', 'light');
      }

      i {
        color: palette-color($palette, 'secondary') !important;
      }

      [icono]:before {
        color: palette-color($palette, 'secondary') !important;
      }

      ul {

        .first_level_menu.true,
        .first_level_menu.true:hover {
          background-color: palette-color($palette,
              'foreground',
              'light'
            ) !important;
        }

        a:hover {
          color: palette-color($palette, 'background', 'light');
          background-color: palette-color($palette,
              'foreground',
              'light'
            ) !important;
        }
      }
    }

    .streamline {

      .ti-id-badge,
      .ti-bar-chart-alt,
      .ti-layout-list-thumb {
        color: palette-color($palette, 'secondary', 'light') !important;
      }
    }

    .graphOptions .ti-angle-right {
      color: palette-color($palette, 'secondary', 'dark') !important;
    }

    .options {
      i {
        color: palette-color($palette, 'secondary', 'dark') !important;
      }
    }

    #dashboard_graph .sl-item.b- {
      .sl-content {
        background-color: palette-color($palette, 'background', 'light') !important;
        border: solid 1px palette-color($palette, 'primary') !important;
      }
    }

    .sl-content.sl-content-flex-container .sl-content-icons-group>* {
      color: palette-color($palette, 'primary') !important;
    }

    .sl-content.sl-content-flex-container .sl-content-icons-group .tipo-dato {
      background: palette-color($palette, 'primary') !important;
      color: palette-color($palette, 'background', 'light') !important;
    }

    .dashboard-editor-options-list {
      .sl-content.sl-content-flex-container .sl-content-icons-group>* {
        color: palette-color($palette, 'background', 'light') !important;
      }

      .sl-content.sl-content-flex-container .sl-content-icons-group .tipo-dato {
        background: palette-color($palette, 'primary') !important;
        color: palette-color($palette, 'background', 'light') !important;
      }

      .ti-angle-double-right {
        color: palette-color($palette, 'secondary', 'light') !important;
      }
    }

    .ant-tabs-tab {
      &.ant-tabs-tab-active {
        background-color: palette-color($palette, 'secondary') !important;
        border: 1px solid palette-color($palette, 'secondary') !important;
        color: palette-color($palette) !important;
      }

      >div {
        font-family: 'Open Sans' !important;

        .ant-tabs-close-x {
          color: palette-color($palette, 'background', 'light') !important;
          font-weight: 600;
        }
      }
    }

    .ant-tabs-nav-wrap .ant-tabs-tab {
      color: #333;

      &:hover {
        color: palette-color($palette, 'secondary') !important;
      }

      &.ant-tabs-tab-active {
        background-color: palette-color($palette, 'secondary') !important;
        border: 1px solid palette-color($palette, 'secondary') !important;
        color: palette-color($palette) !important;

        >div {
          font-family: 'Open Sans' !important;
          color: #fff;

          .ant-tabs-close-x {
            color: palette-color($palette, 'background', 'light') !important;
            font-weight: 600;
          }
        }
      }
    }

    .ant-tabs-left>.ant-tabs-nav .ant-tabs-tab {
      margin: 0px 0px !important;
    }

    .dx-link.dx-icon-comment:before {
      color: palette-color($palette) !important;
    }

    .toggle-view-btn {
      background: palette-color($palette, 'secondary') !important;
    }

    .card {
      .card-link {
        color: palette-color($palette, 'secondary') !important;
      }
    }

    .filter-button-kanban {
      background-color: palette-color($palette) !important;
    }

    .kanban-conditional-selectors {
      background-color: palette-color($palette) !important;
    }

    .button-refresh-kanban {
      background-color: palette-color($palette) !important;
    }

    .titleDivTable {
      border-bottom: 2px solid palette-color($palette);
    }
    .menuItem:hover {
      color: white;
      background-color: palette-color($palette) !important;
    }
    .ant-spin-dot-item{
      background-color: palette-color($palette) !important;
    }
  }
}

/** Dark themes **/
@each $palettename,
$palette in $dark-palettes {
  body.#{$palettename} {
    background-image: initial;
    background-color: rgb(34, 36, 36);
  }

  .#{$palettename} {
    .navbar {
      background-color: palette-color($palette);

      .navbar-brand {
        color: palette-color($palette, 'background', 'light');
      }

      .username {
        color: palette-color($palette, 'background', 'light');
      }

      .userarea .dropdown-menu {
        background-color: palette-color($palette) !important;
      }

      .ti-back-right {
        color: palette-color($palette, 'background', 'light');
      }
    }

    .navbar .oi-menu {
      color: palette-color($palette, 'background', 'light');
    }

    .sidenav {
      background: palette-color($palette, 'foreground', 'light');

      .sidenav_menu {
        background: palette-color($palette, 'foreground', 'light');
      }

      .head-sidebar {
        background-color: palette-color($palette, 'foreground', 'light');
      }

      i {
        color: palette-color($palette, 'secondary') !important;
      }

      [icono]:before {
        color: palette-color($palette, 'accent') !important;
      }

      ul {

        .first_level_menu.true,
        .first_level_menu.true:hover {
          background-color: palette-color($palette,
              'foreground',
              'light'
            ) !important;
        }

        a:hover {
          color: palette-color($palette, 'background', 'light');
          background-color: palette-color($palette,
              'foreground',
              'light'
            ) !important;
        }
      }
    }

    .btn-primary,
    .steps-action button {
      background: palette-color($palette) !important;
    }

    .dsboard-chart__settings i::before {
      color: palette-color($palette);
    }

    .repeater,
    .divcontainer,
    .videocontainer,
    .tablecontainer {
      background: $light2;
      border-top: 3px solid palette-color($palette, 'secondary');

      &.detalle {
        border-top: 3px solid palette-color($palette, 'secondary') !important;

        b {
          background: palette-color($palette, 'secondary') !important;
        }
      }

      b {
        background: palette-color($palette, 'secondary');
        color: palette-color($palette, 'background', 'light');
      }
    }

    .repeater #detalle-right .repetir,
    .repeater #detalle-right .repetir:hover,
    .repeater #detalle-right .repetir:focus {
      background: palette-color($palette) !important;
    }

    .streamline {

      .ti-id-badge,
      .ti-bar-chart-alt,
      .ti-layout-list-thumb {
        color: palette-color($palette, 'secondary', 'light') !important;
      }
    }

    .graphOptions .ti-angle-right {
      color: palette-color($palette, 'primary') !important;
    }

    .options {
      i {
        color: palette-color($palette, 'secondary', 'dark') !important;
      }
    }

    #dashboard_graph .sl-item.b- {
      .sl-content {
        background-color: palette-color($palette, 'background', 'light') !important;
        border: solid 1px palette-color($palette, 'primary') !important;
      }
    }

    .sl-content.sl-content-flex-container .sl-content-icons-group>* {
      color: palette-color($palette, 'primary') !important;
    }

    .sl-content.sl-content-flex-container .sl-content-icons-group .tipo-dato {
      background: palette-color($palette, 'primary') !important;
      color: palette-color($palette, 'background', 'light') !important;
    }

    .dashboard-editor-options-list {
      .sl-content.sl-content-flex-container .sl-content-icons-group>* {
        color: palette-color($palette, 'background', 'light') !important;
      }

      .sl-content.sl-content-flex-container .sl-content-icons-group .tipo-dato {
        background: palette-color($palette, 'primary') !important;
        color: palette-color($palette, 'background', 'light') !important;
      }

      .ti-angle-double-right {
        color: palette-color($palette, 'secondary', 'light') !important;
      }
    }

    .ant-tabs.ant-tabs-card>.ant-tabs-bar .ant-tabs-tab {
      &.ant-tabs-tab-active {
        background-color: palette-color($palette, 'secondary') !important;
        border: 1px solid palette-color($palette, 'secondary') !important;
        color: palette-color($palette, 'background', 'light') !important;
      }

      >div {
        font-family: 'Open Sans' !important;

        .ant-tabs-close-x {
          color: palette-color($palette, 'background', 'light') !important;
          font-weight: 600;
        }
      }
    }

    .subheader .subheader_content h1 {
      color: #f0f0f0;
    }

    .dsboard-template .dsboard-charts .dsboard-chart--width-2.data {
      background-color: rgb(23, 24, 24);
    }

    div#root {
      background-color: rgb(36, 39, 41);
    }

    .sidenav .br-sideleft-menu .menu_item .item_option {
      color: rgb(232, 230, 227);
    }

    .sidenav {
      background-image: initial;
      background-color: rgb(38, 42, 43) !important;

      i {
        color: palette-color($palette) !important;
      }

      [icono]:before {
        color: palette-color($palette, 'accent') !important;
      }
    }

    .dsboard-chart {
      border-top-color: initial;
      border-right-color: initial;
      border-bottom-color: initial;
      border-left-color: initial;
      box-shadow: rgba(50, 46, 86, 0.03) 0px 1px 15px 1px;
      background-color: rgb(24, 26, 27) !important;
    }

    [data-darkreader-inline-fill] {
      fill: #bab4ab !important;
    }

    .dsboard-chart .chart_title,
    .dsboard-chart .graphOptions-title {
      background-image: initial;
      background-color: rgb(24, 26, 27);
      border-bottom-color: palette-color($palette, 'primary') !important;
      color: white;
    }

    .dsboard-chart span.tituloDash {
      font-weight: 600;
      color: #c7c7c7;
    }

    .fa-ellipsis-v:before {
      content: '\f142';
      color: #c7c7c7;
    }

    .dsboard-chart.data h2 {
      font-size: 15px;
      color: #d4d4d4 !important;
    }

    .dsboard-chart.data strong {
      color: #d4d4d4 !important;
    }

    .navbar .sb-avatar__text {
      background: #242729 !important;
      color: palette-color($palette) !important;
    }

    .ant-tabs {
      background-image: initial;
      border-top-color: rgb(48, 52, 54);
      box-shadow: rgba(54, 58, 61, 0.08) 0px 1px 15px 1px;
      background-color: rgb(24, 26, 27);
    }

    .dsboard-chart svg g g text {
      transition: all 0.6s ease-in-out;
      fill: rgb(228, 228, 228) !important;
    }

    .gridContainer {
      background: #181a1b;
    }

    .dx-datagrid-content .dx-datagrid-table .dx-row>td {
      font-size: 12px;
      color: white !important;
      background-color: #181a1b !important;
    }

    .dx-datagrid-total-footer {
      border: 0 !important;
      background-color: #181a1b;
    }

    .css-319lph-ValueContainer,
    .css-11ob95a-container,
    .css-siwxmq-control {
      background-color: #242729 !important;
      border: 1px solid #333333 !important;
    }

    .css-1ppggue-SingleValue {
      color: white !important;
    }

    .dx-datagrid-summary-item {
      color: white;
    }

    .dx-editor-cell .dx-texteditor,
    .dx-editor-cell .dx-texteditor .dx-texteditor-input {
      background: #242729;
    }

    .dropdown-option a {
      color: #fff;
      display: block;

      &:hover {
        color: palette-color($palette) !important;
      }
    }

    .graphOptions {
      background-color: #242729;
      border: solid 1px #24272a;
    }

    i.ti-layout-sidebar-left {
      color: white;
    }

    .dx-toolbar .dx-toolbar-items-container {
      background-color: #181a1b;
    }

    .ant-tabs.ant-tabs-card>.ant-tabs-bar .ant-tabs-tab {
      background-color: rgb(36, 39, 41);
      color: #d4d4d5;
      border: 1px solid #212529;
    }

    .ant-tabs-nav .ant-tabs-tab-active,
    .ant-tabs-nav .ant-tabs-tab-active:hover {
      color: palette-color($palette) !important;
    }

    .css-4ovnjp-container {
      background-color: #242729 !important;
      border: 1px solid #333333 !important;
    }

    .contenedorPb input.form-control[readonly],
    .contenedorPb input.form-control[readonly]:hover,
    .contenedorPb input.form-control[readonly]:focus {
      background-color: #242729 !important;
      border: 1px solid #333333 !important;
      padding-left: 10px !important;
      border-radius: 4px;
      color: #f0f0f0 !important;
    }

    .repeater label,
    .divcontainer label,
    .videocontainer label,
    .tablecontainer label,
    .file-manager label {
      color: white;
    }

    [role='tabpanel'] [role='tabpanel'] .templateTest.col-12.col-xl-12.divcontainer,
    [role='tabpanel'] [role='tabpanel'] fieldset.templateTest.col-12.col-xl-12 {
      background-color: #181a1b !important;
    }

    .ant-tabs-nav .ant-tabs-tab {
      background-color: #181a1b;
      color: #fff;
      border: 1px solid #d4d4d5;
    }

    .formelement label {
      color: white;
    }

    .divcontainer.detalle b {
      box-shadow: none;
    }

    .select-divwin {
      background-color: #242729 !important;
      border: 1px solid #333333 !important;
      border-radius: 4px;
    }

    .contenedorPb .divcontainer>.col-xl-6,
    .detalle-tab>.col-xl-6 {
      padding: 0 8px !important;
      background-color: #17191b !important;
    }

    .contenedorPb input.form-control:focus {
      color: #495057;
      background-color: #242729 !important;
    }

    .gridContainer .dx-toolbar-after .dx-button {
      background-color: #181a1b;
    }

    .dx-toolbar-after .dx-texteditor-input-container input[type='text'],
    .dx-toolbar-after .dx-texteditor-input-container input[type='text']:hover,
    .dx-toolbar-after .dx-texteditor-input-container input[type='text']:focus {
      background-color: #242729 !important;
      border: 1px solid #333333 !important;
      padding-left: 10px !important;
      border-radius: 4px;
      color: #f0f0f0 !important;
    }

    .contenedorPb .dx-texteditor-buttons-container {
      background-color: #333333 !important;
      border: 1px solid #242729 !important;
      border-left: 0 !important;
    }

    .contenedorPb .dx-texteditor-input-container input[type='text'],
    .contenedorPb input[type='text'].form-control,
    .contenedorPb input[type='password'],
    .not-logged input[type='text'],
    .contenedorPb input[type='number'],
    .contenedorPb textarea {
      background-color: #242729 !important;
      border: 1px solid #333333 !important;
      padding-left: 10px !important;
      border-radius: 4px;
      color: #f0f0f0 !important;
    }

    .dx-datagrid-headers .dx-datagrid-content .dx-datagrid-table .dx-row.dx-datagrid-filter-row input:not(.select2-offscreen):not(.select2-default):not(.select2-input):not([type='checkbox']):not([type='radio']):not(.select2-search__field) {
      background-color: rgb(32, 33, 34) !important;
      border-bottom: 1px solid palette-color($palette, 'secondary', 'dark') !important;
    }

    .dx-show-clear-button .dx-clear-button-area{
      border-bottom: 1px solid palette-color($palette, 'secondary', 'dark') !important;
    }
    .dx-datebox .dx-dropdowneditor-input-wrapper{
      border-bottom: 1px solid palette-color($palette, 'secondary', 'dark') !important;
    }


    .repeater,
    .divcontainer,
    .videocontainer,
    .tablecontainer {
      background: #181a1b !important;
    }

    .gridContainer .dx-datagrid-headers .dx-datagrid-content .dx-datagrid-table .dx-row.dx-datagrid-filter-row .dx-dropdowneditor-input-wrapper .dx-texteditor-buttons-container .dx-dropdowneditor-button {
      border-radius: 0 !important;
      background-color: #242729;
    }

    .gridContainer .dx-datagrid-filter-row .dx-editor-cell .dx-editor-with-menu .dx-datebox-calendar {
      background-color: #242729 !important;
    }

    .mdal__container {
      background-color: #181a1b;
    }

    .mdal__container {
      overflow: visible;

      .divcontainer {
        box-shadow: none;
      }
    }

    .file-manager {
      background: #181a1b !important;
    }

    .file-manager-rows {
      background: #181a1b !important;
    }

    .file-manager-nav {
      background: #181a1b !important;
    }

    .file-manager.file-manager .file-manager-header.file-manager-header {
      background: #181a1b !important;
    }

    .file-manager.file-manager .file-manager-header.file-manager-header .file-manager-header__item {
      color: #ffffff !important;
    }

    .file-manager.file-manager .file-manager-rows.file-manager-rows {
      color: #ffffff !important;
    }

    .dx-texteditor.dx-editor-outlined {
      background: transparent;
      border: 0;
    }

    .file-manager-row:nth-child(even) {
      background-color: #181a1b !important;
    }

    .dx-gridbase-container>.dx-datagrid-rowsview.dx-scrollable>.dx-scrollable-wrapper {
      background-color: #181a1b;
    }

    .dashboard-editor-container {
      background-color: #242729;

      #dashboard_graph .sl-item.b- .sl-content {
        background-color: #242729 !important;
        border: solid 1px #873428 !important;
        color: white;
      }
    }

    #dashboard_graph .sl-item.b- .sl-content span {
      color: #ffffff;
    }

    .contenedorPb {
      h3 {
        color: #ffffff;
      }
    }

    .ant-tabs-nav {
      .ant-tabs-tab-active .ant-tabs-tab-btn {
        color: palette-color($palette) !important;

        &:hover {
          color: palette-color($palette) !important;
        }
      }

      button.ant-tabs-tab-remove {
        color: white;
      }
    }

    .form-control.ant-checkbox-wrapper .ant-checkbox-checked .ant-checkbox-inner {
      background-color: palette-color($palette) !important;
    }

    .css-tq48f4-DropdownIndicator {
      color: palette-color($palette) !important;
    }

    h6.headingchild {
      color: #dedede;
    }

    .dx-link.dx-icon-comment:before {
      color: palette-color($palette) !important;
    }

    .ant-steps-item-process>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-title {
      color: palette-color($palette, 'secondary') !important;
    }

    .ant-steps-label-horizontal .ant-steps-item-active .ant-steps-item-icon span {
      color: palette-color($palette, 'secondary') !important;
    }

    .ant-steps-item-wait>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-title {
      color: #fff;
    }

    .ant-steps-item-wait .ant-steps-item-icon {
      background: none;
      color: #fff !important;
    }

    .ant-steps-item-wait .ant-steps-item-icon>.ant-steps-icon {
      color: #fff;
    }

    .btn.btn-light-primary {
      background: palette-color($palette, 'primary', 'light');
      color: palette-color($palette, 'background', 'light');
    }

    //background-color: palette-color($palette);

    .filters-zone {
      background: #181a1b;

      &:hover {
        box-shadow: none;
      }

      .title {
        color: #f5f5f5;
      }
    }

    .dsboard-chart .dsboard-chart__settings--button i::before {
      color: #fff;
    }

    .filters-zone .filters-options .btn-add {
      background-color: palette-color($palette) !important;
    }

    .filter-selectors_container {
      background: #242729;
      color: #fff;
    }

    .filters-zone .filters-options .btn-add {
      background-color: palette-color($palette, 'primary', 'dark') !important;
    }

    .user-dropdown .user-dropdown--options .navi-icon i::before {
      color: palette-color($palette, 'primary', 'light') !important;
    }

    .dashboards .ant-skeleton.ant-skeleton-active {
      background: #00000073 !important;
    }

    .ant-dropdown-menu {
      background: #242729 !important;
    }

    .dsboard-chart.data .card-icon i {
      background-color: palette-color($palette) !important;
    }

    .user-dropdown {
      color: #ffffff !important;
      background-color: #575a5e;
    }

    .user-dropdown .user-dropdown--options .navi-text {
      color: #ffffff !important;
    }

    .user-dropdown .user-dropdown--header .name {
      color: #fff !important;
    }

    .text-muted {
      color: #ffffff !important;
    }

    .ant-dropdown-menu-item,
    .ant-dropdown-menu-submenu-title {
      color: #ffffffd9;
    }

    .ant-dropdown-menu-item>a,
    .ant-dropdown-menu-submenu-title>a {
      color: #ffffffd9;
    }

    .ant-dropdown-menu-item:hover,
    .ant-dropdown-menu-submenu-title:hover {
      background: #ccc;
      color: #000;
    }
    .menuItem:hover {
      color: white;
      background-color: palette-color($palette) !important;
    }
    .ant-spin-dot-item{
      background-color: palette-color($palette) !important;
    }
  }
}

.dx-datagrid-rowsview .dx-row td[bgcolor='#FF0000'] {
  background: #ff4d4f;
}

.url-event {
  height: 100vh;
  align-content: center;
  flex-direction: column;
  display: flex !important;
}

.app-version {
  text-align: center;
  top: 30px;
  position: relative;
  color: #212529;
}

@import "icons.scss";